import {
  BrowserRouter as Router,
  Switch,
  Route,
  // useHistory,
} from 'react-router-dom';
import AdminDashboard from './screens/AdminDashboard';
import ArchitectsApp from './screens/ArchitectsApp';
import IndexScreen from './screens/IndexScreen';

//Test 

function App() {
  return (
    <Router>
    <Switch>
      <Route path="/" exact >
        <IndexScreen />
      </Route>
      <Route path="/admin" exact >
        <AdminDashboard />
      </Route>
      <Route path="/app" exact >
        <ArchitectsApp />
      </Route>
    </Switch>
  </Router>
  );
}

export default App;
