import React, { useState } from "react";
import { ShareholdersDataFormProps } from "./ShareholdersDataForm.types";

const useShareholdersDataForm = ({
    shareHolders,
    setShareHolders,
    setNewShareHolderData
}: ShareholdersDataFormProps) =>{
    const [nameField, setName] = useState<string | number | readonly string[]>();
    const [rfcField, setRfc] = useState<string | number | readonly string[]>();
    const [folioField, setFolio] = useState<string | number | readonly string[]>();
    const [ineCopy, setIneCopy] = useState(null);
    const [rfcCopy, setRfcCopy] = useState(null);

    const addShareHolder = () =>{
        let temporalShareHolders = shareHolders;
        temporalShareHolders.push({
            _name: nameField,
            _rfc: rfcField,
            _folio: folioField,
            _ineImage: ineCopy,
            _rfcImage: rfcCopy
        })
        setShareHolders(temporalShareHolders);
        setNewShareHolderData(false);
    }

    return{
        setName,
        setRfc,
        setFolio,
        addShareHolder,
        setIneCopy,
        setRfcCopy
    }
}

export default useShareholdersDataForm;