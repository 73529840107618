import React, { Dispatch, SetStateAction, useState } from "react";
import { Button, Input } from "rsuite";
import useEconomicActivities from "./EconomicActivities.hooks";
import { EconomicActivitiesProps } from "./EconomicActivities.types";



const EconomicActivities = ( props: EconomicActivitiesProps ) => {

    const{
        setActivity,
        setPercentage,
        addEconomicActivity
    } = useEconomicActivities(props);

    return (
        <div
            style={{
                display: "flex"
            }}
        >
            <Input placeholder="Actividad Económica" onChange={setActivity} />
            <Input placeholder="Porcentaje(SAT)" onChange={setPercentage}/>
            <Button
                onClick={addEconomicActivity}
            >Agregar</Button>
        </div>
    );
}

export default EconomicActivities;