import { Autocomplete, Box, Button, ButtonGroup, Card, CardContent, Collapse, Container, Fab, FormControlLabel, FormGroup, IconButton, ImageList, ImageListItem, List, ListItem, ListItemButton, ListItemText, MenuItem, Modal, Select, SelectChangeEvent, Switch, TextField, Typography } from "@mui/material"
import { Dispatch, SetStateAction, useEffect, useState } from "react"
import { projectType, savedElementsType, TaskTypes, useArchitects } from "./ArchitectsApp.hooks"
import GoogleMapReact from 'google-map-react'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PaidIcon from '@mui/icons-material/Paid';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import GooglePlacesAutocomplete, { geocodeByAddress } from "react-google-places-autocomplete";
import { delay } from "../../global/globalHooks";
import UploaderBroissin from "../../components/UploaderBroissin";
import PhotoCameraBackIcon from '@mui/icons-material/PhotoCameraBack';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { Gantt, Task, EventOption, StylingOption, ViewMode, DisplayOption } from 'gantt-task-react';
import "gantt-task-react/dist/index.css";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { ActivityButton } from "./ArchitectsApp.components";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import DeleteIcon from '@mui/icons-material/Delete';

export const Marker = (props: any) => {
    return(
        <LocationOnIcon color="primary" fontSize="large"/>
    )
}

export const MainScreen = () => {
    return(
        <Typography paragraph>
            Bienvenido
        </Typography>
    )
}

interface ProjectsScreenProps {
    onScreenChange: Dispatch<SetStateAction<number>>;
    onProjectChange: Dispatch<SetStateAction<projectType | undefined>>;
    project?: projectType
}
export const ProjectsScreen = ({onScreenChange, onProjectChange, project} : ProjectsScreenProps) => {
    const {
        getProjects,
        projects,
        refresh,
        currentProject,
        setCurrentProject,
        edit,
        setEdit
    } = useArchitects();

    useEffect(() => {
        getProjects();
        if(project)
        {
            setCurrentProject(project);
        }
    } , [])

    return(
        <Container>
            
            {
                currentProject != undefined ? 
                (
                    <>
                        <IconButton
                            onClick={() => {
                                setCurrentProject(undefined);
                            }}
                        >
                            <ArrowBackIcon/>
                        </IconButton>
                        <Box
                            style={{
                                display: "flex",
                                flexDirection: "column"
                            }}
                        >
                            
                            <Typography>{currentProject._status}</Typography>
                            <TextField
                                disabled={!edit}
                                label="Nombre"
                                defaultValue={currentProject._name}
                                margin="normal"
                            />
                            <Select
                                disabled={!edit}
                                value={currentProject._type}
                                onChange={(event: SelectChangeEvent) => {
                                    let newProject: projectType = currentProject;
                                    newProject._buildingType = event.target.value;
                                    setCurrentProject(newProject);
                                }}
                            >
                                <MenuItem value="">Tipo de construcción</MenuItem>
                                <MenuItem value="Residencial">Residencial</MenuItem>
                                <MenuItem value="Comercial">Comercial</MenuItem>
                            </Select>
                            <TextField
                                disabled={!edit}
                                label="Tamaño"
                                defaultValue={currentProject._size}
                                margin="normal"
                                type="number"
                            />
                            <br/>
                            <Typography>
                                Cliente: {currentProject._customerData?._name} {currentProject._customerData?._surname} {currentProject._customerData?._secondSurname}
                            </Typography>
                            <Typography>
                                Ubicación: {currentProject._location?._adress}
                            </Typography>
                            <br/>
                            <div style={{ height: '50vh', width: '100%' }}>
                                <GoogleMapReact
                                    bootstrapURLKeys={{ key: 'AIzaSyA9OIJXUol-062S6tyAOm4-C7xmyiGma88'}}
                                    defaultCenter={{
                                        lat: currentProject._location?._lat ? parseFloat(currentProject._location?._lat) : 59.95,
                                        lng: currentProject._location?._lgn ? parseFloat(currentProject._location?._lgn) : 30.33
                                    }}
                                    defaultZoom={18}
                                >
                                    <Marker
                                        lat={currentProject._location?._lat ? parseFloat(currentProject._location?._lat) : 59.95}
                                        lng={currentProject._location?._lgn ? parseFloat(currentProject._location?._lgn) : 30.33}
                                    />
                                </GoogleMapReact>
                            </div>
                            <br/>
                            <Button 
                                variant="contained"
                                onClick={() => {
                                    onScreenChange(4);
                                }}
                                startIcon={<PaidIcon/>}
                            >
                                Presupuesto
                            </Button>
                            <br/>
                            <Button 
                                variant="contained"
                                onClick={() => {
                                    onScreenChange(7);
                                }}
                                startIcon={<CalendarMonthIcon/>}
                            >
                                Calendario
                            </Button>
                            <br/>
                            <Button 
                                variant="contained"
                                onClick={() => {
                                    onScreenChange(6);
                                }}
                                startIcon={<PhotoCameraBackIcon/>}
                            >
                                Fotos
                            </Button>

                            {currentProject._multimedia && (
                                <ImageList sx={{ width: 500, height: 450 }} cols={3} rowHeight={164} >
                                    {(currentProject._multimedia).map((image, index)=>(
                                        <ImageListItem key={image}>
                                            <img
                                            src={image}
                                            srcSet={image}
                                            loading="lazy"
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            )}

                            <br/>
                            <Button 
                                variant="contained"
                                color={edit ? "error" : "primary"}
                                onClick={() => {
                                    setEdit(!edit);
                                }}
                            >
                                {edit ?  "Cancelar" : "Editar"}
                            </Button>
                            <br/>
                            {
                                edit && (
                                    <Button 
                                        variant="contained"
                                        color="success"
                                        onClick={() => {
                                            setEdit(!edit);
                                        }}
                                    >
                                        Guardar
                                    </Button>
                                )
                            }
                        </Box>
                    </>
                ) :
                (
                    <List>
                        {
                            refresh ? (
                                projects?.map((project: projectType, index: number) => (
                                    <ListItem key={index}>
                                        <ListItemButton
                                            divider
                                            onClick={
                                                () => {
                                                    setCurrentProject(project);
                                                    onProjectChange(project);
                                                }
                                            }
                                        >
                                            <ListItemText primary={project._name}/>
                                        </ListItemButton>
                                        
                                    </ListItem>
                                ))
                            ) :
                            (
                                <Typography>No hay proyectos registrados</Typography>
                            )
                        }
                    </List>
                )
            }
            {
                currentProject == undefined && (
                    <div
                        style={
                            {
                                position: "fixed",
                                bottom: 30,
                                right: 30
                            }
                        }
                    >
                        <Fab 
                            color="primary" 
                            aria-label="add"
                            onClick={
                                () => {
                                    onScreenChange(5);
                                }
                            }
                        >
                            <AddIcon />
                        </Fab>
                    </div>
                )
            }
            
        </Container>
        
    )
}

interface BudgetScreenProps {
    project: projectType | undefined;
    onScreenChange: Dispatch<SetStateAction<number>>;
}
export const BudgetScreen = ({project, onScreenChange} : BudgetScreenProps) => {

    const {
        getConcepts,
        currentConcept,
        setCurrentConcept,
        concepts,
        elements,
        currentElement,
        setElements,
        setCurrentElement,
        providers,
        setProviders,
        currentProvider,
        setCurrentProvider,
        price,
        setPrice,
        indirectCost,
        quantity,
        setQuantity,
        setCurrentProject,
        saveBudget,
        resetBudget
    } = useArchitects();

    const [show, setShow] = useState({
        concepts: true,
        elements: true,
    })

    const [projectIndex, setProjectIndex] = useState({
        concept: 0,
        element: 0
    })

    useEffect(() => {
        setCurrentProject(project);
        
        getConcepts();
    }, [])

    return(
        <Container>
            <IconButton
                onClick={() => {
                    onScreenChange(1);
                }}
            >
                <ArrowBackIcon/>
            </IconButton>
            <Typography variant="h4">
                {project?._name}
            </Typography>
            
            <Box
                style={{
                    display: "flex",
                    flexDirection: "column"
                }}
            >
                <Typography>
                    {
                        project?._budgets == undefined ? "No hay presupuestos" : ""
                    }
                </Typography>
                <br/>
                {
                    (project?._budgets != undefined) && (
                        <>
                            <Typography>Conceptos registrados</Typography>
                            {
                                project?._budgets?.map((concept, index) => (
                                    <span key={index}>
                                        <Button
                                            variant="contained"
                                            onClick={() => {
                                                if(concepts)
                                                {
                                                    let tmpConcept = concepts?.find((ct) => ct._name == concept._name);
                                                    setCurrentConcept(tmpConcept);
                                                    setElements(tmpConcept?._elements);
                                                    setShow({
                                                        ...show,
                                                        concepts: false,
                                                    })
                                                    setProjectIndex({
                                                        ...projectIndex,
                                                        concept: index
                                                    })
                                                    
                                                }
                                            }}
                                        >
                                            {concept._name}
                                        </Button>
                                        <br/>
                                    </span>
                                    
                                ))
                            }
                        </>
                    )
                }
                <Select
                    value={currentConcept ? concepts?.findIndex((concept) => concept._name == currentConcept._name).toString() : "-1"}
                    onChange={(event: SelectChangeEvent) => {
                        if(concepts)
                        {
                            let pickedConcept = concepts[parseInt(event.target.value)];
                            if(project?._budgets?.find((ct) => ct._name == pickedConcept._name))
                            {
                                setShow({
                                    ...show,
                                    elements: true,
                                    concepts: false,
                                })
                            }else{
                                setShow({
                                    ...show,
                                    elements: false,
                                    concepts: false,
                                })
                            }
                            setCurrentConcept(pickedConcept);
                            setElements(pickedConcept._elements);
                        }
                    }}
                >
                    <MenuItem disabled value={-1}>Selecciona un nuevo concepto</MenuItem>
                    {
                        concepts?.map((concept, index) => (
                            <MenuItem key={index} value={index}>{concept._name}</MenuItem>
                        ))
                    }
                </Select>
                <br/>
                {
                    (show.elements && currentConcept?._elements != undefined) && (
                        <>
                            <Typography>Elementos registrados</Typography>
                            {
                                project?._budgets[projectIndex.concept]._elements?.map((element, index) => (
                                    <span key={index}>
                                        <Button
                                            variant="contained"
                                            onClick={() => {
                                                if(elements)
                                                {
                                                    let tmpElement = elements?.find((ct) => ct._name == element._name);
                                                    setCurrentElement(tmpElement);
                                                    setProviders(tmpElement?._providers);
                                                    setProjectIndex({
                                                        ...projectIndex,
                                                        element: index
                                                    })
                                                    let tmpElements = project._budgets[projectIndex.concept]._elements;
                                                    if(tmpElements != undefined)
                                                    {
                                                        if(tmpElements[index]._provider != undefined)
                                                        {
                                                            let tmpProvider = tmpElements[index]._provider;
                                                            let providerToSet = tmpElement?._providers?.find((provider) => provider._name === tmpProvider)
                                                            setCurrentProvider(providerToSet);
                                                            setPrice(providerToSet ? parseFloat(providerToSet._price) : 0);
                                                            setQuantity(parseInt(tmpElements[index]._quantity));
                                                        }
                                                    }
                                                        
                                                    setShow({
                                                        ...show,
                                                        elements: false,
                                                    })
                                                    
                                                }
                                            }}
                                        >
                                            {element._name}
                                        </Button>
                                        <br/>
                                    </span>
                                    
                                ))
                            }
                        </>
                    )
                }
                {
                    currentConcept && (
                        <Select
                            value={currentElement ? elements?.findIndex((element) => element._name === currentElement._name).toString() : "-1"}
                            onChange={(event: SelectChangeEvent) => {
                                if(elements)
                                {
                                    console.log("Tarjet: ", elements);
                                    let pickedElement = elements[parseInt(event.target.value)];
                                    let savedElement: savedElementsType | undefined;
                                    if(project?._budgets)
                                        savedElement = project?._budgets[projectIndex.concept]._elements?.find((ct) => ct._name == pickedElement._name);
                                    if(savedElement)
                                    {  
                                        setCurrentProvider(pickedElement._providers.find((pr) => pr._name === savedElement?._provider));
                                        setPrice(savedElement._price ? parseFloat(savedElement._price) : 0);
                                        setQuantity(parseInt(savedElement._quantity));
                                    }else{
                                        setCurrentProvider(undefined);
                                        setPrice(0);
                                        setQuantity(0);
                                    }
                                    setShow({
                                        ...show,
                                        elements: false,
                                        concepts: false,
                                    })
                                    setCurrentElement(pickedElement);
                                    setProviders(pickedElement._providers);
                                }
                            }}
                        >
                            <MenuItem disabled value={-1}>Selecciona nuevo un elemento</MenuItem>
                            {
                                elements?.map((element, index) => (
                                    <MenuItem key={index} value={index}>{element._name}</MenuItem>
                                ))
                            }
                        </Select>
                    )
                }
                <br/>
                {
                    (providers && currentConcept) && (
                        <Select
                            value={currentProvider ? providers?.findIndex((provider) => provider._name === currentProvider?._name).toString() : "-1"}
                            onChange={(event: SelectChangeEvent) => {
                                if(providers)
                                {
                                    
                                    setCurrentProvider(providers[parseInt(event.target.value)]);
                                    setPrice(parseFloat(providers[parseInt(event.target.value)]._price));
                                }
                            }}
                        >
                            <MenuItem disabled value={-1}>Selecciona un nuevo proveedor</MenuItem>
                            {
                                providers?.map((element, index) => (
                                    <MenuItem key={index} value={index}>{element._name}</MenuItem>
                                ))
                            }
                        </Select>
                    )
                }
                {
                    currentProvider && (
                        <>
                            <br/>
                            <Typography variant="h5">
                                Unidad: {currentElement?._unit}
                            </Typography>
                            <br/>
                            <Typography variant="h5">
                                Precio Unitario: ${(price / indirectCost).toFixed(2)}
                            </Typography>
                            <br/>
                            <TextField
                                label="Cantidad"
                                type="number"
                                value={quantity}
                                onChange={(event: any) => {
                                    setQuantity(event.target.value);
                                }}
                            />
                            <br/>
                            <Typography variant="h5">
                                Costo Directo: ${price}
                            </Typography>
                            <br/>
                            <Typography variant="h5">
                                Precio Unitario: ${(price / indirectCost).toFixed(2)}
                            </Typography>
                            <br/>
                            <Typography variant="h5">
                                Costo Proveedor: ${price * quantity}
                            </Typography>
                            <br/>
                            <Typography variant="h5">
                                Total: ${(quantity * (price / indirectCost)).toFixed(2)}
                            </Typography>
                            <br/>
                            <Button
                                startIcon={<SaveIcon/>}
                                onClick={() => {
                                    saveBudget();
                                    
                                }}
                            >
                                Guardar
                            </Button>
                        </>
                    )
                }
                {
                    currentConcept && (
                        <Button
                            color="error"
                            onClick={() => {
                                resetBudget();
                                setShow({
                                    concepts: true,
                                    elements: true,
                                })
                            }}
                        >
                            Cancelar
                        </Button>
                    )
                }
            </Box>
        </Container>
    )
}

interface NewProjectScreenProps {
    onScreenChange: Dispatch<SetStateAction<number>>;
}
export const NewProjectScreen = ({onScreenChange} : NewProjectScreenProps) => {
    const {
        name,
        setName,
        location,
        setLocation,
        type,
        setType,
        buildingType,
        setBuildingType,
        size,
        setSize,
        images,
        setImages,
        setRawLocation,
        rawLocation,
        saveNewProject
    } = useArchitects();

    const [showAutoComplete, setShowAutocomplete] = useState(false);
    const [zoomLevel, setZoomLevel] = useState(10);
    //useScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyA9OIJXUol-062S6tyAOm4-C7xmyiGma88&libraries=places");

    const waitApi = async () => {
        // await delay(500);
        // if(document.getElementById("__googleMapsScriptId"))
        // {   
        //     await delay(2000);
        //     setShowAutocomplete(true);
        // }
        // else
        //     waitApi();
        setShowAutocomplete(true);
    }

    useEffect(() => {
        if(rawLocation != null)
        {
            console.log(rawLocation);
            geocodeByAddress(rawLocation.label)
            .then(results => {
                setLocation({
                    lat: results[0].geometry.location.lat().toString(),
                    lng: results[0].geometry.location.lng().toString(),
                    adress: results[0].formatted_address,
                });
                setZoomLevel(18);
            })
            .catch(error => console.error(error));
        }
        
        // setShowAutocomplete(false);
    }, [rawLocation])

    return(
        <Container>
            <IconButton
                onClick={() => {
                    onScreenChange(1);
                }}
            >
                <ArrowBackIcon/>
            </IconButton>
            <Box
                style={{
                    flexDirection: "column",
                    display: "flex",
                }}
            >
                <Typography variant="h4">
                    Crear un nuevo proyecto
                </Typography>
                <br/>
                <TextField 
                    label="Nombre del proyecto"
                    onChange={
                        (event: any) => {
                            setName(event.target.value);
                        }
                    }
                />
                <br/>

                <Select
                    defaultValue="-1"
                    onChange={
                        (event: SelectChangeEvent) => {
                            setType(event.target.value);
                        }
                    }
                >
                    <MenuItem disabled value="-1">Giro</MenuItem>
                    <MenuItem value="Comercial">Comercial</MenuItem>
                    <MenuItem value="Residencial">Residencial</MenuItem>
                </Select>
                <br/>

                <Select
                    defaultValue="-1"
                    onChange={
                        (event: SelectChangeEvent) => {
                            setBuildingType(event.target.value);
                        }
                    }
                >
                    <MenuItem disabled value="-1">Tipo</MenuItem>
                    <MenuItem value="Casa">Casa</MenuItem>
                    <MenuItem value="Departamentos">Departamentos</MenuItem>
                </Select>
                <br/>

                {
                    showAutoComplete && (
                        <GooglePlacesAutocomplete 
                            apiKey="AIzaSyA9OIJXUol-062S6tyAOm4-C7xmyiGma88"
                            onLoadFailed={(error) => (
                                console.error("Could not inject Google script", error)
                            )}
                            selectProps={{
                                rawLocation,
                                onChange: setRawLocation
                            }}
                        />
                    )
            
                }
                
                <br/>
                <div style={{ height: '50vh', width: '100%' }}>
                    <GoogleMapReact
                        bootstrapURLKeys={{ 
                            key: 'AIzaSyA9OIJXUol-062S6tyAOm4-C7xmyiGma88',
                            language: 'es',
                            region: 'mx',
                            libraries:['places'],
                        }}
                        center={{
                            lat: location?.lat ? parseFloat(location?.lat) : 19.3911658,
                            lng: location?.lng ? parseFloat(location?.lng) : -99.4244843
                        }}
                        zoom={zoomLevel}
                        onGoogleApiLoaded={() => {waitApi()}}
                    >
                        <Marker
                            lat={location?.lat ? parseFloat(location?.lat) : 19.3911658}
                            lng={location?.lng ? parseFloat(location?.lng) : -99.4244843}
                        />
                        
                    </GoogleMapReact>
                </div>
                <br/>
                <UploaderBroissin accept="image/*" setValue={setImages} multiple/>
                <br/>
                <TextField 
                    label="M2"
                    type="number"
                    onChange={
                        (event: any) => {
                            setSize(event.target.value);
                        }
                    }
                />
                <br/>
                <Button
                    variant="contained"
                    onClick={() => {
                        saveNewProject(onScreenChange);
                    }}
                >
                    Registrar nuevo proyecto
                </Button>
            </Box>
        </Container>
    )
}

export const PhotoScreen = ({onScreenChange, project}: BudgetScreenProps) => {

    const {
        images,
        setImages,
        savePhotos,
    } = useArchitects();

    useEffect(() => {
    }, [])

    return(
        <Container>
            <IconButton
                onClick={() => {
                    onScreenChange(1);
                }}
            >
                <ArrowBackIcon/>
            </IconButton>
            <Box
                style={{
                    flexDirection: "column",
                    display: "flex"
                }}
            >
                {
                    (project?._multimedia ) && (project._multimedia.length > 0) ? (
                        <Typography>
                            Si hay fotos guardadas
                        </Typography>
                    ) :
                    (
                        <Typography>
                            No hay fotos guardadas
                        </Typography>
                    )
                }
                <br/>
                <UploaderBroissin accept="image/*" setValue={setImages} multiple/>
                <br/>
                <Button
                    onClick={() => {
                        if(project){
                            if(project._multimedia)
                                savePhotos(project._id, project._multimedia);
                            else
                                savePhotos(project._id);
                        }
                            
                    }}
                >
                    Guardar fotos
                </Button>
            </Box>
        </Container>
    )
}

interface ScheduleScreenProps {
    project: projectType | undefined;
    onScreenChange: Dispatch<SetStateAction<number>>;
    setProject: Dispatch<SetStateAction<projectType | undefined>> ;
}
interface AutocompleteProps{
    label: string;
    id: string;
}
export const ScheduleScreen = ({project, onScreenChange, setProject}: ScheduleScreenProps) => {
    const [showTasks, setShowTasks] = useState(true);
    const [mode, setMode] = useState<any>("Day");
    const [show, setShow] = useState(
        {
            activityForm: false,
            taskForm: false,
            taskDependencies: false,
        }
    );
    const [taskList, setTaskList] = useState<AutocompleteProps[]>([]);
    const [localRefresh, setLocalRefresh] = useState(true);
    
    const {
        activityName,
        setActivityName,
        currentProject, setCurrentProject,
        saveActivity,
        currentActivity,
        setCurrentActivity,
        taskName, setTaskName,
        taskDate, setTaskDate,
        taskDesc, setTaskDesc,
        saveTask,
        debugDelete,
        refresh, setRefresh,
        tasks, setTasks,
        getStartEndDateForProject,
        codeTask, decodeTask,
        intanceOfActivityTypes, instanceOfTaskTypes,
        saveOnCloud,
        currentTask, setCurrentTask,
        currentDependencie, setCurrentDependencie,
        saveDependencie,
        handleTaskChange
    } = useArchitects();
    useEffect(() => {
        setLocalRefresh(refresh);
    },[refresh])
    const handleAddTask = (id: string) =>
    {
        setShow({...show, taskForm: true});
        setCurrentActivity(
            currentProject?._milestones?._activities?.find((ac) => ac._id == id)
        );
    }

    

    const handleProgressChange = async (task: Task) => {
        let update = decodeTask(task);
        if(instanceOfTaskTypes(update))
        {
            let aux = task?.project?.split("_"); 
            if(aux)
            {
                let projectId = aux[aux.length - 1];
                let activity = currentProject?._milestones?._activities?.find(a => a._id == projectId);
                let newtasks = activity?._tasks?.filter(t => t._id != update?._id);
                newtasks?.push(update);
                if(activity?._tasks != undefined)
                {
                    let activities = currentProject?._milestones?._activities?.filter(a => a._id != projectId);
                    let tmpProject = currentProject;
                    activity._tasks = newtasks;
                    activities?.push(activity);
                    if(tmpProject?._milestones?._activities)
                    {
                        tmpProject._milestones._activities = activities;
                        setCurrentProject(tmpProject);
                        saveOnCloud();
                    }
                }
                    
            }
            
        }
        setTasks(tasks?.map(t => (t.id === task.id ? task : t)));
    };

    const handleExpanderClick = (task: Task) => {
        setTasks(tasks?.map(t => (t.id === task.id ? task : t)));
    };
    
    const handleDblClick = (task: Task) => {
        setCurrentTask(task);
        setShow({...show, taskDependencies: true});
        if(tasks)
        {
            let tsk: Task[] = tasks.filter(t => t.type == "task" && t.project == task.project && t.id != task.id);
            let opt: AutocompleteProps[] = []; 
            tsk.forEach(element => {
                opt.push({
                    label: element.name,
                    id: element.id
                });
            });
            setTaskList(opt);
        }
        
    };

    useEffect(() => {
        if(currentProject == undefined)
        {
            setCurrentProject(project);
            let activities = project?._milestones?._activities;
            let tmpTask: Task[] = [];
            if(activities && tasks == undefined)
            {
                if(activities.length > 0)
                {
                    activities.forEach(a => {
                        let newAct = codeTask(a);
                        if(newAct)
                            tmpTask.push(newAct);
                        if(a._tasks)
                        {
                            a._tasks.forEach(t => {
                                if(t)
                                {
                                    let newTask = codeTask(t, a._id.toString());
                                    if(newTask)
                                    {
                                        tmpTask.push(newTask);
                                    }
                                }
                                    
                            });
                        }
                    });
                    setTasks(tmpTask);
                }
            }
        }
    },[])
    return(
        <Container>
            <Button
                onClick={() => {
                    debugDelete();
                }}
            >
                Borrar todo
            </Button>
            <Typography variant="h5">
                Calendario
            </Typography>
            <Box
                style={{
                    display: "flex",
                    flexDirection: "row"
                }}
            >
                <FormGroup>
                    <FormControlLabel 
                        control={
                            <Switch 
                                checked={showTasks} 
                                onChange={(event) => setShowTasks(event.target.checked)}
                            />
                        } 
                        label="Mostrar lista de tareas" 
                    />
                </FormGroup>
                <ButtonGroup variant="contained" aria-label="outlined primary button group">
                    <Button onClick={() => setMode("Day")}>Días</Button>
                    <Button onClick={() => setMode("Week")}>Semanas</Button>
                    <Button onClick={() => setMode("Month")}>Meses</Button>
                </ButtonGroup>
            </Box>
            {
                (tasks && refresh && tasks.length > 0) && (
                    <Box
                        style={{
                            width: "100%"
                        }}
                    >
                        <Gantt 
                            onExpanderClick={handleExpanderClick}
                            tasks={tasks} 
                            listCellWidth={showTasks ? "150px" : ""}
                            viewMode={mode}
                            onDateChange={(task) => handleTaskChange(task)}
                            onProgressChange={handleProgressChange}
                            onDoubleClick={handleDblClick}
                        />
                    </Box>
                )
            }
            <br/>
            <Box
                style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center"
                }}
            >
                <Typography variant="h5">
                    Actividades
                </Typography>
                <IconButton
                    onClick={
                        () => {
                            setShow({
                                ...show,
                                activityForm: !show.activityForm
                            })
                        }
                    }
                >
                    {
                        show.activityForm ? (
                            <CancelIcon/>
                        ) : 
                        (
                            <AddCircleIcon/>
                        )
                    }
                </IconButton>
                {
                    show.activityForm && (
                        <Box
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center"
                            }}
                        >
                            <TextField 
                                label="Nombre de la actividad"
                                onChange={
                                    (event: any) => {
                                        setActivityName(event.target.value);
                                    }
                                }
                            />
                            <IconButton
                                onClick={
                                    () => {
                                        let updateProject: projectType | void = saveActivity();
                                        console.log(updateProject);
                                        
                                    }
                                }
                            >
                                <SaveIcon/>
                            </IconButton>
                        </Box>
                    )
                }
                
            </Box>
            <List>
                {
                    refresh && (
                        currentProject?._milestones?._activities?.map((activity, index) => {
                            return(
                                <ActivityButton 
                                    key={index}
                                    i={index}
                                    activity={activity}
                                    onAdd={handleAddTask}
                                    onDelete={(id: string) => {
                                        let project = currentProject;
                                        let tList = tasks;
                                        if(project && project._milestones && tList)
                                        {
                                            project._milestones._activities = project._milestones?._activities?.filter(a => a._id != id);
                                            console.log(tList);
                                            tList = tList.filter(t => t.id != "Project_"+id);
                                            console.log(tList);
                                            setTasks(tList);
                                            saveOnCloud();
                                        }
                                    }}
                                />
                            )
                        })
                    )
                    
                }
            </List>
            <Modal
                open={show.taskDependencies}
                onClose={() => {setShow({...show, taskDependencies: false}); setCurrentTask(undefined)}}
            >
                <Card
                    style={{
                        position: "absolute",
                        top: '50%',
                        left: '50%',
                        height: '90%',
                        transform: 'translate(-50%, -50%)',
                    }}
                >
                    <CardContent
                        style={{
                            display: "flex",
                            flexDirection: "column"
                        }}
                    >
                        <Box>
                            <TextField 
                                label="Nombre de la tarea"
                                defaultValue={currentTask?.name}
                                onChange={
                                    (event: any) => {
                                        let task = currentTask;
                                        if(task)
                                            task.name = event.target.value;
                                    }
                                }
                            />
                            <IconButton edge="end" aria-label="delete"
                                onClick={() => {
                                    let task = currentTask;
                                    let tList = tasks;
                                    let project = currentProject;
                                    if(task && tList && project)
                                    {
                                        let activity = project._milestones?._activities?.find(a => a._id == task?.project?.split('_').pop());
                                        if(activity)
                                        {
                                            activity._tasks?.splice(activity._tasks?.findIndex(t => t._id == task?.id.split('_').pop()), 1);
                                            project._milestones?._activities?.filter(a => a._id == activity?._id).push(activity);
                                            tList = tList.filter(t => t.id != task?.id);
                                            setTasks(tList);
                                            saveOnCloud(true);
                                        }
                                    }
                                }}
                            >
                                <DeleteIcon color="error"/>
                            </IconButton>
                        </Box>
                        
                        <Button 
                            onClick={() => {
                                let task = currentTask;
                                let tasksList = tasks;
                                if(task)
                                {
                                    let dTask = decodeTask(task);
                                    if(instanceOfTaskTypes(dTask))
                                    {
                                        let project = currentProject;
                                        if(task && project)
                                        {
                                            let activity = project._milestones?._activities?.find(a => a._id == task?.project?.split('_').pop());
                                            if(activity?._tasks)
                                            {
                                                activity._tasks[activity._tasks.findIndex(t => t._id == task?.id.split('_').pop())] = dTask;
                                                project._milestones?._activities?.filter(a => a._id == activity?._id).push(activity);
                                                if(tasksList)
                                                    tasksList[tasksList?.findIndex(t => t.id == task?.id)].name = task.name;
                                                saveOnCloud(true);
                                                setShow({...show, taskDependencies: false}); 
                                                setCurrentTask(undefined);
                                            }
                                        }
                                    }
                                }
                            }}
                        >
                            Cambiar nombre
                        </Button>
                        
                        <Typography>Dependencias: </Typography>
                        {
                            localRefresh &&
                                currentTask?.dependencies ? (
                                <List dense>
                                {
                                    
                                    currentTask?.dependencies?.map((d, index) => (
                                        <ListItem
                                        key={index}
                                        secondaryAction={
                                            <IconButton edge="end" aria-label="delete"
                                                onClick={() => {
                                                    let tsk = tasks?.find(t => t.id == d);
                                                    if(tsk)
                                                    {
                                                        saveDependencie(tsk);
                                                        setShow({...show, taskDependencies: false}); 
                                                        setCurrentTask(undefined);
                                                    }
                                                }}
                                            >
                                            <DeleteIcon />
                                            </IconButton>
                                        }
                                        >
    
                                        <ListItemText
                                            primary={tasks?.find(t => t.id == d)?.name}
                                        />
                                        </ListItem>
                                    ))
                                }
                                    </List>
                                ) :
                                (
                                    <Typography>No hay dependencias</Typography>
                                )
                        }
                        <br/>
                        {
                            tasks && (
                                <Autocomplete
                                    getOptionLabel={option => option.label}
                                    disablePortal
                                    id="combo-box-demo"
                                    options={taskList}
                                    sx={{ width: 300 }}
                                    renderInput={(params) => <TextField {...params} label="Selecciona una tarea" />}
                                    onChange={(event: any, newValue: AutocompleteProps | null) => {
                                        if(newValue)
                                            setCurrentDependencie(tasks.find(t => t.id == newValue.id));
                                    }}
                                />
                            )
                        }
                        <Button 
                            onClick={() => {
                                if(currentDependencie)
                                {
                                    saveDependencie(currentDependencie);
                                    setShow({...show, taskDependencies: false}); 
                                    setCurrentTask(undefined);
                                }
                                
                            }}
                        >
                            Cambiar dependencia
                        </Button>
                        <Button 
                            onClick={() => {setShow({...show, taskDependencies: false}); setCurrentTask(undefined)}}
                            color="error"
                        >
                            Cancelar
                        </Button>
                    </CardContent>
                </Card>
            </Modal>
            <Modal
                open={show.taskForm}
                onClose={() => setShow({...show, taskForm: false})}
            >
                <Card
                    style={{
                        position: "absolute",
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                    }}
                >
                    <CardContent
                        style={{
                            display: "flex",
                            flexDirection: "column"
                        }}
                    >
                        <Typography>
                            {
                                currentActivity?._title
                            }
                        </Typography>
                        <TextField 
                            label="Nombre de la tarea"
                            onChange={
                                (event: any) => {
                                    setTaskName(event.target.value);
                                }
                            }
                        />
                        <br/>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                label="Fecha de inicio"
                                value={taskDate}
                                onChange={(newValue) => {
                                    setTaskDate(newValue);
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                        <br/>
                        <TextField 
                            label="Comentario (opcional)"
                            onChange={
                                (event: any) => {
                                    setTaskDesc(event.target.value);
                                }
                            }
                        />
                        <Button
                            onClick={() => {
                                if(taskDate != undefined && taskName != undefined)
                                {
                                    saveTask();
                                    setShow({
                                        ...show,
                                        taskForm: false
                                    })
                                }
                            }}
                        >
                            Guardar
                        </Button>
                    </CardContent>
                </Card>
            </Modal>
        </Container>
    )
}

export const SettingsScreen = () => {
    return(
        <Typography paragraph>
            Configuración
        </Typography>
    )
}

