import React, { useState } from 'react';
import NewUserForm from './../../components/NewUserForm';
import {
    Sidenav, 
    Nav, 
    Container,
    Header,
    Content,
    Sidebar
} from 'rsuite';
import NewStudioForm from '../../components/NewStudioForm';
import NewProviderTypeForm from '../../components/NewProviderTypeForm';
import NewProviderForm from '../../components/NewProviderForm';
import NewElementForm from '../../components/NewElementForm';
import globalStyles from '../../global/globalStyles.module.sass';
import DashboardIcon from '@rsuite/icons/Dashboard';
import UserInfoIcon from '@rsuite/icons/UserInfo';
import PeoplesIcon from '@rsuite/icons/Peoples';
import AdminIcon from '@rsuite/icons/Admin';
import CombinationIcon from '@rsuite/icons/Combination';
import PagePreviousIcon from '@rsuite/icons/PagePrevious';
import PageNextIcon from '@rsuite/icons/PageNext';
import PeopleFliterIcon from '@rsuite/icons/PeopleFliter';
import ExitIcon from '@rsuite/icons/Exit';
import ConceptCatalogue from '../../components/ConceptCatalogue';
import ProviderCatalogue from '../../components/ProviderCatalogue';
import { useHistory } from 'react-router-dom';

const AdminDashboard = () => {
    
    const [expanded, setExpanded] = React.useState(true);
    const [activeKey, setActiveKey] = React.useState('1');
    const history = useHistory();
    const verifyLogin = () => {
        if(localStorage.getItem('access_token')){
            return true;
        }
        return false;
    }

    useState(()=>{
        if(!verifyLogin()){
            //window.location.href = '/';
        }
    })

    return (
        <div>
            <Container
                className={globalStyles.flexContainer}
            >

                <Sidebar
                    style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
                    className={globalStyles.NavigatorWrapper}
                    width={expanded ? 260 : 56}
                    collapsible
                >
                    <Sidenav 
                        activeKey={activeKey} 
                        onSelect={setActiveKey} 
                        expanded={expanded} 
                        appearance="subtle" 
                        collapsible
                    >
                        <Sidenav.Body>
                            <Nav>
                                
                                <Nav.Item eventKey="1"  icon={<DashboardIcon />}>
                                    Dashboard
                                </Nav.Item>
                                <Nav.Item eventKey="7" icon={<CombinationIcon />}>
                                    Catálogo de conceptos
                                </Nav.Item>
                                <Nav.Item eventKey="8" icon={<PeoplesIcon />}>
                                    Padrón de proveedores
                                </Nav.Item>
                                <Nav.Item eventKey="2" icon={<UserInfoIcon />}>
                                    Agregar usuario
                                </Nav.Item>
                                <Nav.Item eventKey="3" icon={<PeoplesIcon />}>
                                    Agregar estudio
                                </Nav.Item>
                                <Nav.Item eventKey="4" icon={<PeopleFliterIcon />}>
                                    Agregar elemento
                                </Nav.Item>
                                <Nav.Item eventKey="5" icon={<AdminIcon />}>
                                    Agregar proveedor
                                </Nav.Item>
                                {/*
                                <Nav.Item eventKey="6" icon={<CombinationIcon />}>
                                    Agregar elemento
                                </Nav.Item>
                                */}
                            </Nav>
                            
                        </Sidenav.Body>
                    </Sidenav>
                    <Nav style={{display: 'flex', flexDirection: 'column',}}>
                        <Nav.Item onClick={() => {
                            localStorage.clear();
                            history.push("/");
                        }} icon={<ExitIcon />}>
                            Cerrar sesión   
                        </Nav.Item>
                        <Nav.Item onClick={() => setExpanded(!expanded)} style={{ width: 56, textAlign: 'center' }}>
                            {expanded ? <PagePreviousIcon /> : <PageNextIcon />}
                        </Nav.Item>
                    </Nav>
                </Sidebar>
                <Container 
                className={globalStyles.ContentWrapper}
                style={{
                    paddingLeft: expanded ? 260 : 56, 
                }}>
                    <Header className={globalStyles.Content}>
                        {activeKey === '1' && (<h2>Bienvenido</h2>)}
                        {activeKey === '2' && (<h2>Crear usuario</h2>)}
                        {activeKey === '3' && ( <h2>Crear Estudio</h2> )}
                        {activeKey === '4' && ( <h2>Crear Elemento</h2> )}
                        {activeKey === '5' && ( <h2>Crear proveedor</h2> )}
                        {activeKey === '6' && ( <h2>Crear tipo de elemento</h2> )}
                        {activeKey === '7' && ( <h2>Catálogo de conceptos</h2> )}
                        {activeKey === '8' && ( <h2>Padrón de proveedores</h2> )}
                    </Header>
                    <Content className={globalStyles.Content}>
                        {activeKey === '2' && (<NewUserForm />)}
                        {activeKey === '3' && ( <NewStudioForm/> )}
                        {activeKey === '4' && ( <NewElementForm/> )}
                        {activeKey === '5' && ( <NewProviderForm/> )}
                        {activeKey === '6' && ( <NewElementForm/> )}
                        {activeKey === '7' && ( <ConceptCatalogue/> )}
                        {activeKey === '8' && ( <ProviderCatalogue/> )}
                    </Content>
                </Container>
            </Container>
        </div>
            
    );
}

export default AdminDashboard;