import React, { useState, useEffect } from 'react';
import {
    Input,
    CheckboxGroup,
    Checkbox,
    InputPicker,
    Button
} from 'rsuite';
import globalStyles from '../../global/globalStyles.module.sass';
import { host } from '../../env';

const NewUserForm = () =>{

    // const host = 'http://localhost:4000/';
    // const host = '/';
    // const host = 'http://broissin-env.eba-ykifddpm.ca-central-1.elasticbeanstalk.com/';

    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [second_surname, setSecondSurname] = useState('');
    const [id_type, setIdType] = useState('');
    const [id_checking_account, setIdCheckingAccount] = useState('');
    const [id_bank, setIdBank] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [phone, setPhone] = useState('');
    const [rol, setRoles] = useState('');
    const [status, setStatus] = useState('');
    const [rfc, setRfc] = useState('');
    const [id_studio, setIdStudio] = useState([]);

    useEffect(()=>{
        getAllStudios();
    }, []);

    const [allStudios, setAllStudios] = useState([]);

    const getAllStudios = async() =>{
        const url = host+'studios';
        const response = await fetch(url);
        const obtained = await response.json();
        if(obtained.status){
            setAllStudios(obtained.studios);
            let studiosTemporal = [];
            obtained.studios.map((studio)=>{
                studiosTemporal.push({
                    label: studio._name,
                    value: studio._id,
                    rol: studio._id
                });
            })
        }
    }

    const addUser = async() =>{
        const url = host+'user';
        const token = await localStorage.getItem('access_token');
        console.log('url', url);
        const response = await fetch(url,{
            method: 'POST',
            headers:{
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+token
            },
            body: JSON.stringify({
                _checkingAccount: id_checking_account,
                _bankId: id_bank,
                _name: name,
                _surname: surname,
                _secondSurname: second_surname,
                _email: email,
                _password: password,
                _phone: phone,
                _rol: rol,
                _studioId: id_studio,
                _rfc: rfc,
                _status: status,
            })
        })
        const obtained = await response.json();
        if(!obtained.fail){
            alert('usuario agregado')
        }else{
            alert('Ha ocurrido un error')
        }
    }

    return(
        <div className={globalStyles.FormWrapper}>
            <div>
                <div>Nombre: </div>
                <Input onChange={(response)=>setName(response.toString())} />
                <div>Apellido Paterno: </div>
                <Input onChange={(response)=>setSurname(response.toString())} />
                <div>Apellido Materno: </div>
                <Input onChange={(response)=>setSecondSurname(response.toString())} />
                <div>Tipo de identificación: </div>
                <Input onChange={(response)=>setIdType(response.toString())} />
                <div>Id de banco: </div>
                <Input onChange={(response)=>setIdBank(response.toString())} />
                <div>Cuenta de cheques: </div>
                <Input onChange={(response)=>setIdCheckingAccount(response.toString())} />
                <div>Email: </div>
                <Input onChange={(response)=>setEmail(response.toString())} />
                <div>Contraseña: </div>
                <Input type="password" onChange={(response)=>setPassword(response.toString())} />
                <div>Teléfono: </div>
                <Input onChange={(response)=>setPhone(response.toString())} />
                <div>Rol(es): </div>
                <InputPicker 
                    data={[
                        {label: 'Administrador', value: 'administrator', rol: 'administrator'},
                        {label: 'Director de estudio y administrador', value: 'administrator-studio-director', rol: 'administrator-studio-director'},
                        {label: 'Director de estudio', value: 'studio-director', rol: 'studio-director'},
                        {label: 'Arquitecto', value: 'architect', rol: 'architect'},
                        {label: 'Cliente', value: 'customer', rol: 'customer'}
                    ]}
                    onChange={(response)=>{
                        setRoles(response);
                    }}
                />
                <div>Status: </div>
                <InputPicker 
                    data={[{label: 'Inactivo', value: 'inactive', rol: 'inactive'}, {label: 'Activo', value: 'active', rol: 'active'}]}
                    onChange={(response)=>{
                        setStatus(response);
                    }}
                />
                <div>Rfc</div>
                <Input onChange={(response)=>setRfc(response.toString())} />
                <div>Stuio(s): </div>
                <CheckboxGroup
                    inline
                    name="studio"
                    onChange={
                        (value)=>{
                            setIdStudio(value);
                        }
                    }
                >
                    {allStudios.map((studio)=>(
                        <Checkbox value={studio._id} >{studio._name}</Checkbox>    
                    ))}
                </CheckboxGroup>
                <Button onClick={addUser} >Agregar usuario</Button>
            </div>
        </div>
    )
}

export default NewUserForm;