import React, { useEffect, useState } from "react";
import { Button, Input, InputPicker, Table, InputGroup } from 'rsuite';
import globalStyles from '../../global/globalStyles.module.sass';
import styles from './ConceptCatalogue.module.sass';
import SearchIcon from '@rsuite/icons/Search';
import ConceptView from "../ConceptView";
import { ElementProps } from "../ConceptView/ConceptView.types";
import useConceptCatalogue from './ConceptCatalogue.hooks';

const ConceptCatalogue = () => {

    const {
      conceptView,
      selectecElement,
      setSelectedEelement,
      setConceptView,
      concepts,
      getConcepts
    } = useConceptCatalogue();
  
    return (
        <div className={styles.container}>
            
            <div className={styles.TopOptions}>
            
              <div className={styles.SearchBarWrapper}>
                <InputGroup >
                  <Input placeholder="Busqueda" />
                  <InputGroup.Addon>
                    <SearchIcon />
                  </InputGroup.Addon>
                </InputGroup>
              </div>
              <div className={styles.ButtonWrapper}>
                <Button appearance="primary" 
                  onClick={()=>{
                    setSelectedEelement(null);
                    setConceptView(true)
                  }}
                >Agregar Concepto</Button>
              </div>
            </div>
            <div className={styles.TableWrapper}>
              {
                  (conceptView && selectecElement==null) && (
                    <ConceptView 
                      // data={selectecElement}
                      functionAtTheEnd={getConcepts}
                    />
                  )
              }
              {
                (conceptView && selectecElement!=null) && (
                  <ConceptView 
                    data={selectecElement}
                    functionAtTheEnd={getConcepts}
                  />
                )
              }
              <div className={styles.Table}>
                <Table
                    height={600}
                    className={styles.Table}
                    data={concepts}
                    onRowClick={(rowData: any) => {
                        setSelectedEelement(rowData);
                        console.log(rowData);
                    }}
                >
                    {/* <Table.Column width={70} align="center" fixed sortable>
                      <Table.HeaderCell>Id</Table.HeaderCell>
                      <Table.Cell dataKey="id_element" />
                    </Table.Column> */}
                    <Table.Column width={200} align="center" fixed resizable sortable>
                      <Table.HeaderCell>Elemento</Table.HeaderCell>
                      <Table.Cell dataKey="_name" />
                    </Table.Column>
                    <Table.Column width={400} align="center" fixed resizable>
                      <Table.HeaderCell>Descripción</Table.HeaderCell>
                      <Table.Cell dataKey="_status" />
                    </Table.Column>
                    
                    {/*<Table.Column width={100} align="center" fixed sortable>
                      <Table.HeaderCell>Costo directo</Table.HeaderCell>
                      <Table.Cell dataKey="direct_cost" />
                    </Table.Column>*/}
                    <Table.Column width={300} align="center" fixed>
                      <Table.HeaderCell>Action</Table.HeaderCell>

                      <Table.Cell >
                        {/* <Elemento {} /> */}
                        <span>
                          <a onClick={
                            (props)=>{
                              setConceptView(true);
                              console.log(props);
                          }}> Editar </a> | <a onClick={()=>{
                            
                          }}> Agregar Proveedor </a> |  <a onClick={()=>{}}> Deshabilitar </a>
                                    
                        </span>
                      </Table.Cell>
                    </Table.Column>
                </Table>
              </div>
              
            </div>
            
        </div>
    );
};

export default ConceptCatalogue;