import { useEffect } from "react";

export const useScript = (url: string) => {
    useEffect(() => {
        const script = document.createElement('script');

        script.src = url;
        script.async = true;
        
        document.head.appendChild(script);

        return () => {
            document.head.removeChild(script);
        }
    }, [url]);
}

export const delay = (ms: number) => new Promise(
    resolve => setTimeout(resolve, ms)
);


export const colors = [
    "#d32f2f",
    "#8e24aa",
    "#3949ab",
    "#00838f",
    "#7cb342", 
    "#ffa000", 
    "#f4511e", 
    "#d81b60", 
    "#5e35b1", 
    "#1e88e5", 
    "#00acc1", 
    "#00897b", 
    "#c0ca33", 
    "#fbc02d", 
    "#e64a19"   
]
 
export class Maybe {
    value: any
    constructor(value: any)
    {
        this.value = value
    }

    bind = (func: any) => {
        let value;
        value = func(this.value);
        return new Maybe(value);
    }
}