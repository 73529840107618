import React, { useEffect, useState } from "react";
import { Button, Input, InputPicker } from 'rsuite';
import { useNewElementForm } from './NewElementForm.hooks';
import globalStyles from '../../global/globalStyles.module.sass';
const NewElementForm = () => {

    const { 
        setName,
        setDescription,
        providers,
        setProvider,
        setUnit,
        setUnitPrice,
        setDirectCost,
        setStatus,
        addElement,
        status,
        concepts,
        setConcept
     } = useNewElementForm();

    return (
        <div className={globalStyles.FormWrapper}>
            <div>
                <h2>Nuevo elemento</h2>
                <Input placeholder="Nombre" onChange={setName} />
                <Input placeholder="Descripción" onChange={setDescription} />
                <h5>Proveedor</h5>
                <InputPicker 
                    data={providers}
                    onChange={setProvider}
                />
                <h5>Concepto</h5>
                <InputPicker 
                    data={concepts}
                    onChange={setConcept}
                />
                <Input placeholder="Unidad de medida" onChange={setUnit} />
                <Input placeholder="Precio unitario" onChange={setUnitPrice} />
                <Input placeholder="Costo directo" onChange={setDirectCost} />
                <InputPicker
                    data={[
                        { label: "Activo", value: "active" },
                        { label: "Inactivo", value: "inactive" },
                    ]}
                    value={status}
                    onChange={setStatus}
                />
                <Button
                    onClick={addElement}
                >Agregar</Button>
            </div>
        </div>
    );
};

export default NewElementForm;