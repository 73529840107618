import React, { useState } from "react";
import { EconomicActivitiesProps } from './EconomicActivities.types';

const useEconomicActivities = ({
    economicActivities,
    setEconomicActivities,
    setNewEconomicActivity
}: EconomicActivitiesProps) => {

    const [activityField, setActivity] = useState<string | number | readonly string[]>("");
    const [percentageField, setPercentage] = useState<string | number | readonly string[]>("");

    const addEconomicActivity = () => {
        let temporalEconomicActivities = economicActivities;
        temporalEconomicActivities.push({ activity: activityField, percentage: percentageField });
        setEconomicActivities(temporalEconomicActivities);
        setNewEconomicActivity(false);
    }

    return{
        activityField,
        setActivity,
        percentageField,
        setPercentage,
        addEconomicActivity
    }
}

export default useEconomicActivities;