import React, { useEffect, useState } from "react";
import SignaturePad from "react-signature-pad-wrapper";
import { Input, InputPicker, DatePicker, Button, RadioGroup, Radio } from 'rsuite';
import globalStyles from '../../global/globalStyles.module.sass';
import EconomicActivities from "../EconomicActivities";
import FollowingContactsForm from "../FollowingContactsForm";
import ShareholdersDataForm from "../ShareholdersDataForm";
import UploaderBroissin from "../UploaderBroissin";
import useNewProviderForm from "./NewProviderForm.hooks";
import styles from "./NewProviderForm.module.sass";
const NewProviderForm = () => {

    const {
        setPersona,
        setFormatType,
        persona,
        setNewEconomicActivity,
        newEconomicActivity,
        economicActivities,
        setEconomicActivities,
        shareHolders,
        newShareHolderData,
        setNewShareHolderData,
        followingContacts,
        newFollowingContact,
        setFollowingContacts,
        setNewFollowingContact,
        setShareholders,
        signaturePad,
        setCurpImage,
        verImagen,
        addProvider,
        setRfcImage,
        setAddressImage,
        setLegalRepresentativeImageIne,
        setLegalRepresentativeImageConstitutiveAct,
        setName,
        setRfc,
        setCurp,
        setStreet,
        setSuburb,
        setExternalNumber,
        setInternalNumber,
        setLocation,
        setState,
        setPhone,
        setWebSite,
        setSocialNetwork,
        setEmpleoyees,
        setEconomicActivity,
        setMoralName,
        setMoralOfficeNumber,
        setMoralCellPhone,
        setMoralEmail,
        setMoralConstitutiveAct,
        setMoralNotarialNumber,
        setMoralNameOfNotary,
        setMoralJob,
        setBankInstitution,
        setBankAccount,
        setCLABE,
        setBankSucursal,
        setPostalCode,
        setCity
    } = useNewProviderForm();
    
    return (
        <div className={styles.FormWrapper}>
            <h2>Nuevo proveedor</h2>
            <RadioGroup
                className={styles.RadioGroup}
                name="radioList"
                inline
                appearance="picker"
                defaultValue="moral"
                onChange={setPersona}
            >
                <span
                    className={styles.radioGroupLabel}
                >
                    Tipo de persona:
                </span>
                <Radio value="moral">Persona moral</Radio>
                <Radio value="fisica">Persona física</Radio>
            </RadioGroup>
            <RadioGroup
                className={styles.RadioGroup}
                style={{ marginBottom: '40px' }}
                name="radioList"
                inline
                appearance="picker"
                defaultValue="alta"
                onChange={setFormatType}
            >
                <span
                    className={styles.radioGroupLabel}
                >
                    Tipo de formulario:
                </span>
                <Radio value="alta">Inscripción</Radio>
                <Radio value="modificacion">Modificación</Radio>
                <Radio value="renovacion">Renovación</Radio>
            </RadioGroup>
            <Input placeholder="Nombre Completo o razón social de la empresa" onChange={setName} />
            <Input placeholder="RFC" onChange={setRfc} />
            <UploaderBroissin setValue={setRfcImage} disableAfterOneUse text='Copia de la INE aquí' maxPreviewFileSize={1} />
            {persona === "fisica" && (
                <>
                    <Input placeholder="CURP" onChange={setCurp} />
                    <UploaderBroissin disableAfterOneUse setValue={setCurpImage} text="Copia de la Curp" />
                </>
            )}
            <h4>Dirección</h4>
            <Input placeholder="Municipio" onChange={setCity} />
            <Input placeholder="Calle" onChange={setStreet} />
            <Input placeholder="Colonia" onChange={setSuburb} />
            <Input placeholder="Número exterior"  onChange={setExternalNumber} />
            <Input placeholder="Número interior" onChange={setInternalNumber} />
            <Input placeholder="Código postal"  onChange={setPostalCode} />
            <Input placeholder="Localidad" onChange={setLocation}/>
            <Input placeholder="Estado" onChange={setState} />
            <UploaderBroissin disableAfterOneUse setValue={setAddressImage} text='Comprobante de domicilio' />
            <Input placeholder="Teléfono" onChange={setPhone}/>
            <Input placeholder="Página web" onChange={setWebSite}/>
            <Input placeholder="Redes sociales (Linkedin, Facebook, Instagram, Pinterest, otros)" onChange={setSocialNetwork} />
            <Input placeholder="Número de empleados directos" type="number" min={0} onChange={setEmpleoyees} />
            <Input placeholder="Giro comercial" onChange={setEconomicActivity} />

            <Button appearance="primary" 
                onClick={() => setNewEconomicActivity(!newEconomicActivity)}
            >Agregar Actividad económica</Button>
            {economicActivities.map((economicActivity) => (
                <div
                    style={{
                        display: "flex",
                    }}
                >
                    <div>{economicActivity.activity}</div>
                    <div>{economicActivity.percentage}</div>
                </div>
            ))}
            {newEconomicActivity && (
                <EconomicActivities economicActivities={economicActivities} setEconomicActivities={setEconomicActivities} setNewEconomicActivity={setNewEconomicActivity} />
            )}

            {persona === "moral" && (
                <>
                    <h4>Datos del Representante o Apoderado Legal</h4>
                    <Input placeholder="Nombre Completo" onChange={setMoralName} />
                    <UploaderBroissin disableAfterOneUse setValue={setLegalRepresentativeImageIne} text="Copia fotostática del INE" />
                    <Input placeholder="Teléfono de oficina" onChange={setMoralOfficeNumber} />
                    <Input placeholder="Celular" onChange={setMoralCellPhone} />
                    <Input placeholder="Correo electrónico" onChange={setPhone} />
                    <Input placeholder="Numero de acta constitutiva" onChange={setMoralConstitutiveAct} />
                    <UploaderBroissin disableAfterOneUse setValue={setLegalRepresentativeImageConstitutiveAct} text="Acta constitutiva" />
                    <Input placeholder="Número de poder Notarial" onChange={setMoralNotarialNumber} />
                    <Input placeholder="Nombre del notario y número de la notaria" onChange={setMoralNameOfNotary} />
                    <Input placeholder="Cargo dentro de la organización" onChange={setMoralJob} />
                </>
            )}



            <h4>Datos de los accionistas</h4>

            {shareHolders.map((shareHolder) => (

                <div
                    style={{
                        display: "flex",
                    }}
                >
                    <div>{shareHolder._name}</div>
                    <div>{shareHolder._rfc}</div>
                    <div>{shareHolder._folio}</div>
                </div>
            ))}
            {newShareHolderData && (
                <ShareholdersDataForm shareHolders={shareHolders} setNewShareHolderData={setNewShareHolderData} setShareHolders={setShareholders} />
            )}
            <Button
            appearance="primary" 
                onClick={() => setNewShareHolderData(!newShareHolderData)}
            >Agregar accionista </Button>


            <h4>Datos del (los) Contacto en seguimiento a los servicios / productos contratados</h4>
            {followingContacts.map((followingContact, index) => (
                <div
                    key={index}
                    style={{
                        display: "flex",
                    }}
                >
                    <div>{followingContact._name}</div>
                    <div>{followingContact._phone}</div>
                    <div>{followingContact._cellphone}</div>
                    <div>{followingContact._email}</div>
                    <div>{followingContact._position}</div>
                    <div>{followingContact._area}</div>
                </div>
            ))}
            {newFollowingContact && (
                <FollowingContactsForm setFollowingContacts={setFollowingContacts} followingContacts={followingContacts} setNewFollowingContact={setNewFollowingContact} />
            )}
            <Button
                onClick={() => setNewFollowingContact(!newFollowingContact)}
            >Agregar Nuevo Contacto de seguimiento</Button>

            <h4>Datos bancarios</h4>
            <Input placeholder="Institución bancaria" onChange={setBankInstitution} />
            <Input placeholder="Cuenta" onChange={setBankAccount} />
            <Input placeholder="CLABE" onChange={setCLABE} />
            <Input placeholder="Sucursal" onChange={setBankSucursal} />
            {/* <Button
                    onClick={() => console.log(economicActivities)}
                >Recargar</Button> */}
            <h4>Agregar firma</h4>
            <div className={styles.signatureWrapper}>
                <SignaturePad
                    ref={signaturePad}
                />;
            </div>
            
            {/* <Button appearance="primary" onClick={verImagen} >Ver firma</Button> */}
            <Button appearance="primary" onClick={addProvider}  >Agregar</Button>
        </div>
    )
};

export default NewProviderForm;