export const useValidation = () => 
{
    const ValidateEmail = (email: string) => {
        let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
        if (reg.test(email) === false) {
            return false;
        }
        else {
            return true;
        }
    }

    const ValidatePhone = (phone: string | number) => 
    {
        if(phone.toString().length != 10)
        {
            return false;
        }else{
            return true;
        }
    }

    return{
        ValidateEmail,
        ValidatePhone
    }
}