import React, { Dispatch, SetStateAction, useState } from "react";
import { Uploader } from "rsuite";


interface UploaderBroissinProps {
    accept?: string;
    action?: string;
    disabled?: boolean;
    setValue: Dispatch<SetStateAction<any>>;
    text?: string;
    multiple?: boolean;
    maxPreviewFileSize?: number | undefined;
    disableAfterOneUse?: boolean;
}

const UploaderBroissin = ({
    accept = "*",
    disabled = false,
    action = '',
    text = 'Arrastra o clickea para seleccionar un archivo.',
    setValue = () => { },
    multiple = false,
    maxPreviewFileSize = undefined,
    disableAfterOneUse = false
}: UploaderBroissinProps) => {

    const styles = {
        lineHeight: '50px',
    };

    const [isDisabled, setIsDisabled] = useState(disabled);

    const evaluateFiles = (files: any) => {
        if (disableAfterOneUse) {
            if(files.length>0){
                setIsDisabled(true);
                setValue(files[(files.length)-1].blobFile);
                console.log("Imagen nueva", files[(files.length)-1].blobFile);
            }else{
                setIsDisabled(false);
                setValue(null);
            }
        } else {
            if (files.length == 0)
                setValue(null);
            if (files.length > 0) {
                let tmp: any = []
                for (let index = 0; index < files.length; index++) {
                    tmp.push(files[index].blobFile)
                }
                setValue(tmp);
            }
            return setIsDisabled(false);
        }
    }

    return (
        <Uploader
            autoUpload={false}
            accept={accept}
            disabled={isDisabled}
            // disabledFileItem
            multiple={multiple}
            maxPreviewFileSize={maxPreviewFileSize}
            //action="//jsonplaceholder.typicode.com/posts/" 
            draggable
            onChange={evaluateFiles} action={""}        >
            <div style={styles}>{text}</div>
        </Uploader>
    );
}

export default UploaderBroissin;