import React, { useEffect, useRef, useState } from "react";
import { ValueType } from "rsuite/esm/Radio";
import { host } from "../../env";


const useNewProviderForm = () => {
    const signaturePad = useRef<any>(null);

    const [persona, setPersona] = useState<ValueType>("moral");
    const [formatType, setFormatType] = useState<ValueType>("alta");
    const [newEconomicActivity, setNewEconomicActivity] = useState(false);

    const [name, setName] = useState<string | number | readonly string[]>();
    const [rfc, setRfc] = useState<string | number | readonly string[]>();
    const [curp, setCurp] = useState<string | number | readonly string[]>();
    const [city, setCity] = useState<string | number | readonly string[]>();
    const [street, setStreet] = useState<string | number | readonly string[]>();
    const [suburb, setSuburb] = useState<string | number | readonly string[]>();
    const [externalNumber, setExternalNumber] = useState<string | number | readonly string[]>();
    const [internalNumber, setInternalNumber] = useState<string | number | readonly string[]>();
    const [postalCode, setPostalCode] = useState<string | number | readonly string[]>();
    const [location, setLocation] = useState<string | number | readonly string[]>();
    const [state, setState] = useState<string | number | readonly string[]>();
    const [phone, setPhone] = useState<string | number | readonly string[]>();
    const [webSite, setWebSite] = useState<string | number | readonly string[]>();
    const [socialNetwork, setSocialNetwork] = useState<string | number | readonly string[]>();
    const [empleoyees, setEmpleoyees] = useState<string | number | readonly string[]>();
    const [economicActivity, setEconomicActivity] = useState<string | number | readonly string[]>();

    /**
     ** In case of moral person
     */

    const [moralName, setMoralName] = useState<string | number | readonly string[]>();
    const [moralOfficeNumber, setMoralOfficeNumber] = useState<string | number | readonly string[]>();
    const [moralCellPhone, setMoralCellPhone] = useState<string | number | readonly string[]>();
    const [moralEmail, setMoralEmail] = useState<string | number | readonly string[]>();
    const [moralConstitutiveAct, setMoralConstitutiveAct] = useState<string | number | readonly string[]>();
    const [moralNotarialNumber, setMoralNotarialNumber] = useState<string | number | readonly string[]>();
    const [moralNameOfNotary, setMoralNameOfNotary] = useState<string | number | readonly string[]>();
    const [moralJob, setMoralJob] = useState<string | number | readonly string[]>();


    /**
     ** Regular Data
     */

    const [bankInstitution, setBankInstitution] = useState<string | number | readonly string[]>();
    const [bankAccount, setBankAccount] = useState<string | number | readonly string[]>();
    const [CLABE, setCLABE] = useState<string | number | readonly string[]>();
    const [bankSucursal, setBankSucursal] = useState<string | number | readonly string[]>();

    const [newShareHolderData, setNewShareHolderData] = useState(false);
    const [newFollowingContact, setNewFollowingContact] = useState(false);

    const [rfcImage, setRfcImage] = useState(null);
    const [curpImage, setCurpImage] = useState(null);
    const [addressImage, setAddressImage] = useState(null);
    const [legalRepresentativeImageIne, setLegalRepresentativeImageIne] = useState(null);
    const [legalRepresentativeImageConstitutiveAct, setLegalRepresentativeImageConstitutiveAct] = useState(null);


    const [economicActivities, setEconomicActivities] = useState<{
        activity: any,
        percentage: any
    }[]>([]);

    const [shareHolders, setShareholders] = useState<{
        _name: any,
        _rfc: any,
        _folio: any,
        _ineImage: any,
        _rfcImage: any
    }[]>([]);

    const [followingContacts, setFollowingContacts] = useState<{
        _name: any,
        _phone: any,
        _cellphone: any,
        _email: any,
        _position: any,
        _area: any,
        _ineImage: any
    }[]>([]);


    const [multimedia, setMultimedia] = useState<{
        _inePhoto?: string;
        _curpPhoto?: string;
        _addressPhoto?: string;
        _legalRepresentativeInePhoto?: string;
        _legalRepresentativeConstitutiveActPhoto?: string;
    }>({});

    const [shareHoldersImages, setShareHoldersImages] = useState<{
        _inePhoto?: string;
        _rfcPhoto?: string;
    }[]>([]);

    const [followingContactsImages, setFollowingContactsImages] = useState<{
        _inePhoto?: string;
    }[]>([]);

    const verImagen = () => {
        console.log(signaturePad.current);
    }

    const getShareHoldersImages = () => {
        let imagesArray: {
            _ineImage: String;
            _rfcImage: String;
        }[] = [];
        shareHoldersImages.map((shareHolderImage: any) => {
            imagesArray.push({
                _ineImage: shareHolderImage._ineImage,
                _rfcImage: shareHolderImage._rfcImage
            })
        })
        return imagesArray;
    }

    const uploadImage = async (imageToAdd: any) =>{
        const formData = new FormData();
        formData.append('image', imageToAdd);
        const response = await fetch(host+'addimage',{
            method: 'POST',
            headers:{
                'Authorization': 'Bearer ' + localStorage.getItem('access_token')
            },
            body: formData
        });
        const obteined = await response.json();
        if(obteined.status){
            console.log("regresaré: ", obteined.images[0]);
            return obteined.images[0];
        }            
        return '';
    }

    const getFollowingContactsImages = () => {
        let imagesArray: {
            _ineImage: String;
        }[] = [];
        followingContactsImages.map((followingContactImage: any) => {
            imagesArray.push({
                _ineImage: followingContactImage._ineImage
            })
        })
        return imagesArray;
    }

    const addProvider = async () => {
        let formData = new FormData();
        let temporalShareHolders: {
            _name: any,
            _rfc: any,
            _folio: any,
        }[] = [];

        let temporalFollowingContacts: {
            _name: any,
            _phone: any,
            _cellphone: any,
            _email: any,
            _position: any,
            _area: any,
        }[] = [];

        shareHolders.forEach(shareHolder => {
            // formData.append("image_Ine_shareholder_"+shareHolder.rfc, shareHolder.ineImage);
            // formData.append("image_rfc_shareholder_"+shareHolder.rfc, shareHolder.rfcImage);
            temporalShareHolders.push({
                _name: shareHolder._name,
                _rfc: shareHolder._rfc,
                _folio: shareHolder._folio,
                //ineImage: shareHolder.ineImage,
                //rfcImage: shareHolder.rfcImage
            });
        });

        followingContacts.forEach(followingContact => {

            // formData.append("image_Ine_followingContact_"+followingContact.email, followingContact.ineImage);

            temporalFollowingContacts.push({
                _name: followingContact._name,
                _phone: followingContact._phone,
                _cellphone: followingContact._cellphone,
                _email: followingContact._email,
                _position: followingContact._position,
                _area: followingContact._area
            });
        });


        let info = {
            _type: persona,
            _formatType: formatType,
            _name: name,
            _rfc: rfc,
            _curp: curp,
            _address: {
                _city: city,
                _street: street,
                _suburb: suburb,
                _externalNumber: externalNumber,
                _internalNumber: internalNumber,
                _postalCode: postalCode,
                _location: location,
            },
            _phone: phone,
            _webSite: webSite,
            _socialNetwork: socialNetwork,
            _directEmployees: empleoyees,
            _economicActivity: economicActivity,
            _economicActivities: economicActivities,
            // Datos de apoderado legal
            _legalRepresentative: {
                _moralName: moralName,
                _moralOfficeNumber: moralOfficeNumber,
                _moralCellPhone: moralCellPhone,
                _moralEmail: moralEmail,
                _moralConstitutiveAct: moralConstitutiveAct,
                _moralNotarialNumber: moralNotarialNumber,
                _moralNameOfNotary: moralNameOfNotary,
                _moralJob: moralJob,
            },
            _shareHoldersData: temporalShareHolders,
            _followingContacts: temporalFollowingContacts,
            // Datos de banco
            _bankData: {
                _bankInstitution: bankInstitution,
                _bankAccount: bankAccount,
                _CLABE: CLABE,
                _bankSucursal: bankSucursal
            }
        }
        let responseImage: any;
        let responseTotal;
        interface multimediaProps{
            _inePhoto?: string;
            _curpPhoto?: string;
            _addressPhoto?: string;
            _legalRepresentativeInePhoto?: string;
            _legalRepresentativeConstitutiveActPhoto?: string;
        }
        let multimediaRara: multimediaProps;
        formData = new FormData();
        multimediaRara = multimedia;
        if (addressImage !== null) {
            multimediaRara = {...multimediaRara, _addressPhoto: await uploadImage(addressImage)}
            // setMultimedia({ ...multimedia, _addressPhoto: await uploadImage(addressImage) })
        }
        if (curpImage !== null) {
            // setMultimedia({ ...multimedia, _curpPhoto: await uploadImage(curpImage) })
            multimediaRara = {...multimediaRara, _curpPhoto: await uploadImage(curpImage)}
        }
        if (rfcImage !== null) {
            // setMultimedia({ ...multimedia, _inePhoto: await uploadImage(rfcImage) })
            multimediaRara = {...multimediaRara, _inePhoto: await uploadImage(rfcImage)};
        }
        console.log("Multimedia: ",await multimediaRara);
        formData = new FormData();
        if (legalRepresentativeImageIne !== null) {
            formData.append('image', legalRepresentativeImageIne)
            responseImage = await fetch(host + 'addimage', {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('access_token')
                },
                body: formData
            })
            responseTotal = await responseImage.json();
            if (responseTotal.status) {
                await setMultimedia({ ...multimedia, _legalRepresentativeInePhoto: responseTotal.images[0] })
            }
        }
        formData = new FormData();
        if (legalRepresentativeImageConstitutiveAct !== null) {
            formData.append('image', legalRepresentativeImageConstitutiveAct)
            responseImage = await fetch(host + 'addimage', {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('access_token')
                },
                body: formData
            })
            responseTotal = await responseImage.json();
            if (responseTotal.status) {
                await setMultimedia({ ...multimedia, _legalRepresentativeConstitutiveActPhoto: responseTotal.images[0] })
            }
        }

        let temporalShareHoldersToJson: any = [];

        for (let i = 0; i < shareHolders.length; i++) {
            temporalShareHoldersToJson.push({
                ...shareHolders[i],
                _ineImage: shareHoldersImages[i]._inePhoto,
                _rfcImage: shareHoldersImages[i]._rfcPhoto
            })
        }

        setShareholders(temporalShareHoldersToJson);

        let temporalFollowingContactsToJson: any = [];

        for (let i = 0; i < followingContacts.length; i++) {
            temporalFollowingContactsToJson.push({
                ...followingContacts[i],
                _ineImage: followingContactsImages[i]._inePhoto
            })
        }

        setFollowingContacts(temporalFollowingContactsToJson);


        let responseData = await fetch(host + 'providers', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('access_token')
            },
            body: JSON.stringify({
                _type: persona,
                _formatType: formatType,
                _name: name,
                _rfc: rfc,
                _curp: curp,
                _address: {
                    _city: city,
                    _street: street,
                    _suburb: suburb,
                    _externalNumber: externalNumber,
                    _internalNumber: internalNumber,
                    _postalCode: postalCode,
                    _location: location,
                },
                _multimedia: await multimediaRara,
                _phone: phone,
                _webSite: webSite,
                _socialNetwork: socialNetwork,
                _directEmployees: empleoyees,
                _economicActivity: economicActivity,
                _economicActivities: economicActivities,
                // Datos de apoderado legal
                _legalRepresentative: {
                    _moralName: moralName,
                    _moralOfficeNumber: moralOfficeNumber,
                    _moralCellPhone: moralCellPhone,
                    _moralEmail: moralEmail,
                    _moralConstitutiveAct: moralConstitutiveAct,
                    _moralNotarialNumber: moralNotarialNumber,
                    _moralNameOfNotary: moralNameOfNotary,
                    _moralJob: moralJob,
                },
                _shareHoldersData: temporalShareHolders,
                _followingContacts: temporalFollowingContacts,
                // Datos de banco
                _bankData: {
                    _bankInstitution: bankInstitution,
                    _bankAccount: bankAccount,
                    _CLABE: CLABE,
                    _bankSucursal: bankSucursal
                }
            })
        })
        
        const data = await responseData.json();
        if (data.status) {
            alert('Se ha registrado correctamente el proveedor');
        }
        else {
            alert('Ha ocurrido un error al registrar el proveedor');
        }


        if (rfcImage !== null)
            formData.append("image_rfc_" + rfc, rfcImage)
        if (curpImage != null)
            formData.append("image_curp_" + rfc, curpImage)
        if (addressImage != null)
            formData.append("image_address_" + rfc, addressImage);
        if (legalRepresentativeImageIne != null)
            formData.append("image_ine_" + moralEmail, legalRepresentativeImageIne);
        if (legalRepresentativeImageConstitutiveAct != null)
            formData.append("image_constitutive_act_" + moralEmail, legalRepresentativeImageConstitutiveAct);



        // const response = await fetch(host+'providers', {
        //     method: 'POST',
        //     headers:{
        //         'Authorization': 'Bearer '+localStorage.getItem('access_token')
        //     },
        //     body: formData
        //   });

        //   const data = await response.json();
        //   console.log(data);
    }

    return {
        persona,
        setPersona,
        formatType,
        setFormatType,
        newEconomicActivity,
        setNewEconomicActivity,
        newShareHolderData,
        setNewShareHolderData,
        newFollowingContact,
        setNewFollowingContact,
        economicActivities,
        setEconomicActivities,
        shareHolders,
        setShareholders,
        followingContacts,
        setFollowingContacts,
        signaturePad,
        setCurpImage,
        verImagen,
        addProvider,
        setRfcImage,
        setAddressImage,
        setLegalRepresentativeImageIne,
        setLegalRepresentativeImageConstitutiveAct,
        setName,
        setRfc,
        setCurp,
        setStreet,
        setSuburb,
        setExternalNumber,
        setInternalNumber,
        setPostalCode,
        setLocation,
        setState,
        setPhone,
        setWebSite,
        setSocialNetwork,
        setEmpleoyees,
        setEconomicActivity,
        setMoralName,
        setMoralOfficeNumber,
        setMoralCellPhone,
        setMoralEmail,
        setMoralConstitutiveAct,
        setMoralNotarialNumber,
        setMoralNameOfNotary,
        setMoralJob,
        setBankInstitution,
        setBankAccount,
        setCLABE,
        setBankSucursal,
        setCity
    }
}

export default useNewProviderForm;