import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { host } from "../../env";

export const useLogin = () => {

    const [isOpenModal, setIsOpenModal] = React.useState(false);
    const [isRegisterActive, setIsRegisterActive] = React.useState(false);
    const [username, setUsername] = useState<string | number | readonly string[]>("axelrubio.mx@broissin.com");
    const [password, setPassword] = useState<string | number | readonly string[]>("123");

    const [email, setEmail] = useState<string | number | readonly string[]>();
    const [name, setName] = useState<string | number | readonly string[]>();
    const [lastName, setLastName] = useState<string | number | readonly string[]>();
    const [lastName2, setLastName2] = useState<string | number | readonly string[]>();
    const [password2, setPassword2] = useState<string | number | readonly string[]>();
    const [phone, setPhone] = useState<string | number | readonly string[]>();

    const [error, setError] = useState({
        phone: {
            isError: false,
            message: "El número no es valido"
        },
        email: {
            isError: false,
            message: "El email no es valido"
        },
        password: {
            isError: false,
            message: "Las contraseñas no coinciden"
        }
    })

    const history = useHistory();
    const login = async () => {
        let url = host + 'login';
        let response = await fetch(url, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                _email: username,
                _password: password
            })
        });

        let data = await response.json();
        if (data.access_token) {
            localStorage.setItem('access_token', data.access_token);
            if(data.info._rol == "studio-director")
            {
                history.push("/app");
            }
            if(data.info._rol == "administrator")
            {
                history.push("/admin");
            }
        }

    }


    const register = async () => {
        const url = host + 'studio_director';
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                _name: name,
                _surname: lastName,
                _secondSurname: lastName2,
                _phone: phone,
                _email: email,
                _password: password,
            })
        });

        const data = await response.json();
        if(await data.status){
            console.log(data);
        }
    }

    const closeModal = () => setIsOpenModal(false);
    const openModal = () => setIsOpenModal(true);

    const closeRegister = () => setIsRegisterActive(false);
    const openRegister = () => setIsRegisterActive(true);

    return {
        login,
        setUsername,
        setPassword,
        isRegisterActive,
        closeRegister,
        openRegister,
        closeModal,
        openModal,
        isOpenModal,
        register,
        setName,
        name,
        setLastName,
        lastName,
        setLastName2,
        setPhone,
        phone,
        setPassword2,
        password2,
        error,
        setError,
        password,
        setEmail
    }
}

