import {useState, useEffect} from "react";
import { ElementProps } from "../ConceptView/ConceptView.types";

const useConceptCatalogue = () => {
    const [conceptView, setConceptView] = useState(false);
    const [concepts, setConcepts] = useState([]);
    const [selectecElement, setSelectedEelement] = useState<ElementProps | null >();

    const getConcepts = async () => {
        let token = await localStorage.getItem('access_token');
        const response = await fetch('http://broissin-env.eba-ykifddpm.ca-central-1.elasticbeanstalk.com/concepts', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })
        const obtained = await response.json();
        setConcepts(obtained.concepts);
    }

    useEffect(() => {
        getConcepts();
    }, []);

    return{
        conceptView,
        setConceptView,
        concepts,
        selectecElement,
        setSelectedEelement,
        getConcepts
    }
};

export default useConceptCatalogue;