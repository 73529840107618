import React, { useState } from "react";
import styles from '../ConceptCatalogue/ConceptCatalogue.module.sass';
import { Button, Input } from 'rsuite';
import { ConceptViewProps } from "./ConceptView.types";
import useConceptView from './ConceptView.hooks';

const ConceptView = (props: ConceptViewProps) => {

    const {
        data,
        name,
        setName,
        description,
        setDescription,
        unit,
        setUnit,
        unit_price,
        setUnit_price,
        //direct_cost,
        //setDirect_cost,
        addOrChangeConcept
    } =useConceptView(props)

    return (
        <div className={styles.infoView} >
            <div className={styles.infoView}>
                <h4>Editar</h4>
                {data &&(
                    <p><strong>ID: </strong>{data._id}</p>
                )}
                <br />
                <strong>Nombre: </strong>
                <Input placeholder="Nombre" value={name} onChange={setName} />
                {/* <strong>Descripción: </strong> */}
                {/* <Input placeholder="Descripción" value={description} onChange={setDescription} />
                <strong>Unidad: </strong> */}
                {/* <Input placeholder="Unidad" value={unit} onChange={setUnit} />
                <strong>Precio unitario: </strong>
                <Input placeholder="Precio unitario" value={unit_price} onChange={setUnit_price} /> */}
                
            </div>
            <Button
                onClick={addOrChangeConcept}
            >Guardar</Button>
        </div>
    );
};

export default ConceptView;