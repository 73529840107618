import React, { useState } from "react";
import { FollowingContactsProps } from "./FollowingContactsForm.types";

const useFollowingContactsForm = ({
    followingContacts,
    setFollowingContacts,
    setNewFollowingContact
}: FollowingContactsProps) => {
    const [nameField, setName] = useState<string | number | readonly string[]>("");
    const [phoneField, setPhoneField] = useState<string | number | readonly string[]>("");
    const [cellphoneField, setCellPhone] = useState<string | number | readonly string[]>("");
    const [emailField, setEmail] = useState<string | number | readonly string[]>("");
    const [positionField, setPosition] = useState<string | number | readonly string[]>("");
    const [areaField, setArea] = useState<string | number | readonly string[]>("");
    const [ineImage, setIneImage] = useState(null);


    const addFollowingContact = () => {
        let temporalFollowingContacts = followingContacts;
        temporalFollowingContacts.push({ _name: nameField, _phone: phoneField, _cellphone: cellphoneField, _email: emailField, _position: positionField, _area: areaField, _ineImage: ineImage });
        setFollowingContacts(temporalFollowingContacts);
        setNewFollowingContact(false);
    }

    return {
        setName,
        setPhoneField,
        setCellPhone,
        setEmail,
        setPosition,
        setArea,
        addFollowingContact,
        setIneImage,
        ineImage
    }
}

export default useFollowingContactsForm;