import { useHistory } from "react-router-dom";
import { Dispatch, SetStateAction, useState, useEffect } from "react"
import { host } from "../../env";
import { colors, Maybe } from "../../global/globalHooks";
import { Task } from 'gantt-task-react';

interface providersType {
    _name: string;
    _price: string;
}
interface elementsType {
    _name: string;
    _unit: string;
    _providers: providersType[];
}
interface conceptsType {
    _name: string;
    _elements: elementsType[];
}
export interface savedElementsType{
    _name: string | undefined;
    _provider: string | undefined;
    _price: string | undefined;
    _quantity: string;
}
export interface savedConceptsType {
    _name: string | undefined;
    _elements: savedElementsType[] | undefined;
}

export interface onlineProvidersType {
    _name: string;
    _price: string;
    
}
export interface onlineElementsType {
    _name: string;
    _unit: string;
    _providers: onlineProvidersType[];
}
export interface onlineDataType {
    _name: string;
    _elements: onlineElementsType[];
}

export interface projectType {
    _buildingType: string;
    _customerData: {_name: string, _secondSurname: string, _surname: string} | undefined;
    _id: string;
    _location: {_lat: string, _lgn: string, _adress: string} | undefined;
    _name: string;
    _preparedBy: string;
    _size: string | undefined;
    _status: string;
    _studioId: string;
    _type: string;
    _budgets: savedConceptsType[];
    _multimedia?: string[];
    _milestones?: CalendarTypes;
}


export interface GeoLocation {
    lat: string | undefined;
    lng: string | undefined;
    adress: string | undefined;
}

export interface DatesTypes
{
    _id: string;
    _startDate: string;
    _endDate: string;
}

export interface TaskTypes 
{
    _id: string;
    _title: string;
    _date: string;
    _endDate: string;
    _percentage: string;
    _color: string;
    _comment: string;
    _dependencies: string[];
}

export interface ActivityTypes {
    _id: string;
    _initDate: string | undefined;
    _endDate: string | undefined;
    _title: string;
    _description: string;
    _color: string;
    _tasks: TaskTypes[] | undefined;
}
export interface CalendarTypes {
    _id: string | undefined;
    _activities: ActivityTypes[] | undefined;
}


export const useArchitects = () => {
    //Global
    const [openDrawer, setOpenDrawer] = useState(true);
    const [currentScreen, setCurrentScreen] = useState(0);
    const screens = [
        "Inicio",
        "Proyectos",
        "Configuración"
    ]

    //Project screen
    const [projects, setProjects] = useState<projectType[]>();
    const [refresh, setRefresh] = useState(true);
    const [currentProject, setCurrentProject] = useState<projectType>();
    const [edit, setEdit] = useState(false);

    //New Project Screen
    const [name, setName] = useState("");
    const [buildingType, setBuildingType] = useState("");
    const [type, setType] = useState("");
    const [location, setLocation] = useState<GeoLocation>();
    const [size, setSize] = useState(0);
    const [images, setImages] = useState<any>(null);
    const [rawLocation, setRawLocation] = useState<any>(null);
    const [multimedia, setMultimedia] = useState<any>(null);

    //BudgentScreen
    const [concepts, setConcepts] = useState<conceptsType[]>();
    const [currentConcept, setCurrentConcept] = useState<conceptsType>();
    const [elements, setElements] = useState<elementsType[]>();
    const [currentElement, setCurrentElement] = useState<elementsType>();
    const [providers, setProviders] = useState<providersType[]>();
    const [currentProvider, setCurrentProvider] = useState<providersType>();
    const [price, setPrice] = useState(0);
    const [quantity, setQuantity] = useState(0);
    const indirectCost = 0.85;

    //ScheduleScreen
    const [activityName, setActivityName] = useState("");
    const [currentActivity, setCurrentActivity] = useState<ActivityTypes>();
    const [taskName, setTaskName] = useState("");
    const [taskDate, setTaskDate] = useState<Date | null | undefined>();
    const [taskDesc, setTaskDesc] = useState("");
    const [tasks, setTasks] = useState<Task[]>();
    const [currentTask, setCurrentTask] = useState<Task>();
    const [currentDependencie, setCurrentDependencie] = useState<Task>();

    //Get data
    const getToken = () => {
        return localStorage.getItem('access_token');
    }

    useEffect(()=>{
        console.log("Imagenes",images);
    }, [images])

    const history = useHistory();

    const getProjects = async () => {
        let url = host + 'projects';
        let token = getToken();
        const response = await fetch(url, {
            headers: {
                Authorization: 'Bearer ' + token,
            }
        })
        
        const obtained = await response.json();
        setProjects(obtained.projects);
        setRefresh(true);
    }

    const getConcepts = async () => {
        try {
            let url = host + 'concepts';
            let token = getToken();
            const response = await fetch(url, {
                headers: {
                    Authorization: 'Bearer ' + token,
                }
            })
            
            const obtained = await response.json();
            setConcepts(await obtained.concepts);
            setRefresh(true);
        } catch (error) {
            console.log(error);
        }
        
        
    }

    //Set Data
    const savePhotos = async (id: string, originalArray?: string[]) => {
        try{
            if(images != null)
            {
                let formData = new FormData();
                let url = host + 'addimage';
                let token = getToken();
                let imagesToSend: any[] = [];
                console.log(images);
                for (let index = 0; index < images.length; index++) {
                    const element = images[index];
                    formData.append("image_place_" + id + "_" + index, element);
                }
                const responseImages = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + token
                    },
                    body: formData
                })
                const obtainedResponse = await responseImages.json();
                console.log(obtainedResponse);
                if(obtainedResponse.status)
                {
                    imagesToSend = obtainedResponse.images
                    if(originalArray)
                        imagesToSend = originalArray.concat(obtainedResponse.images);
                    url = host + 'project/'+id;
                    const responseUpdate = await fetch(url, {
                        method: 'PUT',
                        headers: {
                            Authorization: 'Bearer ' + token,
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            _multimedia: imagesToSend
                        })            
                    })
                    const obtainedUpdate = await responseUpdate.json();
                    console.log(obtainedResponse);
                    if(obtainedResponse.status)
                    {

                    }else{

                    }
                }
            }
        }catch(error){
            console.log(error);
        }
    }

    const saveNewProject = async (onScreenChange: Dispatch<SetStateAction<number>>) => {
        try{
            let formData = new FormData();
            let url = host + 'projects';
            let token = getToken();
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    Authorization: 'Bearer ' + token,
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    _name: name,
                    _buildingType: buildingType,
                    _type: type,
                    _size: size.toString(),
                    // _customerId: '02126c7b-8414-4035-b8e4-159c0831e5c7',
                    _customerData: {
                        _name: 'Pedro',
                        _surname: 'Perez',
                        _secondSurname: 'Perez',
                    },
                    _location: {
                        _lat: location?.lat,
                        _lgn: location?.lng,
                        _adress: location?.adress
                    },
                    _status: '1'
                })
            });

            const obtained = await response.json();
            if (await obtained.status){
                savePhotos(obtained._projectId);
            }

            


        }catch(error)
        {
            console.log(error);
        }
    }

    const saveBudget = async () => {
        try {
            if(currentProject != undefined)
            {
                let updateProject: projectType = currentProject; 
                let newBudgets: savedConceptsType[] = currentProject._budgets == undefined ? [] : currentProject._budgets;
                let conceptIndex = newBudgets?.findIndex((concept) => concept._name === currentConcept?._name);
                if(conceptIndex != -1)
                {
                    let conceptToUpdate = newBudgets[conceptIndex];
                    let newElements = conceptToUpdate._elements == undefined ? [] : conceptToUpdate._elements;
                    let elementsIndex = newElements?.findIndex((element) => element._name === currentElement?._name);
                    if(elementsIndex !== undefined && newElements !== undefined)
                    {
                        newElements[elementsIndex] = {
                            _name: currentElement?._name,
                            _provider: currentProvider?._name,
                            _price: currentProvider?._price,
                            _quantity: quantity.toString()
                        };
                    }else{
                        newElements?.push({
                            _name: currentElement?._name,
                            _provider: currentProvider?._name,
                            _price: currentProvider?._price,
                            _quantity: quantity.toString()
                        });
                    }
                    conceptToUpdate._elements = newElements;
                }else{
                    newBudgets?.push({
                        _name: currentConcept?._name,
                        _elements: [
                            {
                                _name: currentElement?._name,
                                _provider: currentProvider?._name,
                                _price: currentProvider?._price,
                                _quantity: quantity.toString()
                            }
                        ]
                    });
                }

                updateProject = {
                    ...updateProject,
                    _budgets: newBudgets
                };


                let url = host + 'project/'+currentProject._id;
                let token = getToken();
                const response = await fetch(url, {
                    method: 'PUT',
                    headers: {
                        Authorization: 'Bearer ' + token,
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        _name: updateProject._name,
                        _buildingType: updateProject._buildingType,
                        _type: updateProject._type,
                        _size: updateProject._size,
                        _customerId: '02126c7b-8414-4035-b8e4-159c0831e5c7',
                        _location: updateProject._location,
                        _budgets: updateProject._budgets,
                        _status: '1'
                    })
                });
                const obtained = await response.json();
                console.log(obtained);
                if(obtained.status)
                {
                    resetBudget();
                }
            }
        } catch(error) {
            console.log(error);
        }
    }

    const resetBudget = () => {
        setCurrentConcept(undefined);
        setCurrentProvider(undefined);
        setCurrentElement(undefined);
        setPrice(0);
        setQuantity(0);
    }



    const saveActivity = () => {
        if(activityName && currentProject)
        {
            let tmpProject: projectType = currentProject;
            if(tmpProject)
            {
                if(!tmpProject._milestones)
                {
                    let newCalendar: CalendarTypes = {
                        _id: "0",
                        _activities: []
                    };
                    tmpProject._milestones = newCalendar;
                }
                var RandomNumber = Math.floor(Math.random() * colors.length);
                let date = new Date();
                let newActivity: ActivityTypes = {
                    _id: tmpProject._milestones?._activities ? (tmpProject._milestones?._activities?.length + 1).toString() : "0",
                    _title: activityName,
                    _endDate: date.toString(),
                    _initDate: date.toString(),
                    _description: "",
                    _color: colors[RandomNumber],
                    _tasks: []
                };
                tmpProject?._milestones?._activities?.push(newActivity);
                let tmpTask: Task[];
                if(tasks)
                {
                    tmpTask = tasks;
                }else{
                    tmpTask = [];
                }
                tmpTask.push({
                    start: date,
                    end: date,
                    name: activityName,
                    id: 'Project_' + newActivity._id,
                    type: "project",
                    progress: 0,
                    hideChildren: true
                })
                setTasks(tmpTask);
                setCurrentProject(tmpProject);
                saveOnCloud(true);
            }
            
        }
    }

    const saveTask = () => {
        if(currentProject)
        {
            try{
                let tmpActivity = currentActivity;
                let tmpProject = currentProject;
                let index = currentProject._milestones?._activities?.findIndex((ac) => ac._id == tmpActivity?._id);
            
                setRefresh(false);
                if(tmpActivity != undefined && taskDate && taskName && index != undefined)
                {
                    let id = 0;
                    const plusDay = new Date(taskDate?.toString());
                    plusDay?.setDate(plusDay.getDate() + 1);
                    if(tmpActivity._tasks)
                    {
                        id = tmpActivity._tasks.length + 1;
                    }
                    tmpActivity._tasks?.push({
                        _id: id.toString(),
                        _title: taskName,
                        _date: taskDate.toString(),
                        _endDate: plusDay.toString(),
                        _percentage: "0",
                        _color: tmpActivity._color,
                        _comment: taskDesc ? taskDesc : "",
                        _dependencies: []
                    })
                    if(tmpProject._milestones?._activities != undefined )
                    {
                        tmpProject._milestones._activities[index] = tmpActivity;
                    }
                    let tmpTask: Task[];
                    if(tasks)
                    {
                        tmpTask = tasks;
                    }else{
                        tmpTask = [];
                    }
                    let newTask: Task = {
                        start: taskDate,
                        end: plusDay,
                        name: taskName,
                        id: 'Task_' + id,
                        type: "task",
                        progress: 0,
                        styles: {progressColor: tmpActivity._color},
                        project: 'Project_' + tmpActivity._id,
                    }
                    tmpTask.push(newTask);
                    handleTaskChange(newTask, false);
                    setTasks(tmpTask);
                    setCurrentProject(tmpProject);
                    saveOnCloud(true);
                }
                
            }catch(error){
                console.log(error);
            }
        }
    }
    const handleTaskChange = (task: Task, save: boolean = true) => {
        if(tasks)
        {
            let copy: any = task;
            let canUpdate = true;
            if(task.dependencies)
            {
                task.dependencies.forEach(d => {
                    let t = tasks.find(t => t.id == d);
                    if(t)
                    {
                        if(t.end > task.start)
                        {
                            canUpdate = false;
                            setRefresh(false);
                            return;
                        }
                    }
                });
            }
            
            if(copy.barChildren)
            {
                copy.barChildren.forEach((ch: any) => {
                    if(task.end > ch.start)
                    {
                        console.log(task.end);
                        console.log(ch.start);
                        canUpdate = false;
                        setRefresh(false);
                        return;
                    }
                });
            }
            setRefresh(true);
            if(!canUpdate) return;
            let newTasks = tasks.map(t => (t.id === task.id ? task : t));
            if (task.project) {
                const [start, end] = getStartEndDateForProject(newTasks, task.project);
                const project = newTasks[newTasks.findIndex(t => t.id === task.project)];
                if (
                    project.start.getTime() !== start.getTime() ||
                    project.end.getTime() !== end.getTime()
                ) {
                    const changedProject = { ...project, start, end };
                    let updateActivity = decodeTask(changedProject);
                    let updateTask = decodeTask(task);
                    if(intanceOfActivityTypes(updateActivity) && instanceOfTaskTypes(updateTask))
                    {
                        let tmpAct = updateActivity?._tasks?.filter((t) => t._id != updateTask?._id);
                        let tmpActivities = currentProject?._milestones?._activities?.filter((a) => a._id != updateActivity?._id);
                        let tmpProject = currentProject;
                        tmpAct?.push(updateTask);
                        updateActivity._tasks = tmpAct;
                        tmpActivities?.push(updateActivity);
                        if(tmpProject?._milestones)
                        {
                            tmpProject._milestones._activities = tmpActivities;
                            setCurrentProject(tmpProject);
                            if(save)
                                saveOnCloud(false);
                        }
                        
                    }
                    newTasks = newTasks.map(t =>
                        t.id === task.project ? changedProject : t
                    );
                }
            }

            setTasks(newTasks);
        }
    };

    const saveDependencie = (task: Task) => {
        if(currentProject)
        {
            try
            {
                
                let tmpTasks = tasks;
                let tsk = tmpTasks?.find(t => t.id == currentTask?.id);
                if(task.dependencies?.find(d => d == tsk?.id))
                {
                    return;
                }
                if(!tsk?.dependencies?.find(d => d == task?.id) && task && tsk && tmpTasks && task.project) 
                {
                    if(!tsk.dependencies)
                    {
                        tsk.dependencies = [];
                    }
                    if(tsk.start < task.end)
                    {
                        let diff = Math.abs(tsk.start.getTime() - tsk.end.getTime());
                        let diffDays = Math.ceil(diff / (1000 * 3600 * 24)); 
                        tsk.start = task.end
                        const plusDay = new Date(tsk.start?.toString());
                        plusDay?.setDate(plusDay.getDate() + diffDays);
                        tsk.end = plusDay;
                        handleTaskChange(tsk, false);
                    }
                    tsk.dependencies.push(task.id);
                    let index = tmpTasks.findIndex(t => t.id == currentTask?.id);
                    tmpTasks[index] = tsk;
                    setTasks(tmpTasks);
                    setCurrentTask(tsk);
                    editTask(tsk);
                }
                else if(tsk?.dependencies?.find(d => d == task?.id) && tmpTasks)
                {
                    tsk.dependencies = tsk.dependencies.filter(d => d != task?.id);
                    let index = tmpTasks.findIndex(t => t.id == currentTask?.id);
                    tmpTasks[index] = tsk;
                    setTasks(tmpTasks);
                    setCurrentTask(tsk);
                    editTask(tsk);
                }
            }
            catch(error)
            {
                console.log(error);
            }
            
        }
    }

    const editTask = (task: Task) => {
        let dTask = decodeTask(task);
        if(instanceOfTaskTypes(dTask))
        {
            let project = currentProject;
            let actId = task.project?.split('_').pop();
            let id = task.id.split('_').pop();   

            if(project)
            {
                let crtActivity = project?._milestones?._activities?.find(a => a._id == actId);
                let crtTasks = crtActivity?._tasks?.filter(t => t._id != id);
                crtTasks?.push(dTask);
                if(crtActivity)
                {
                    crtActivity._tasks = crtTasks;
                    let activities = project?._milestones?._activities?.filter(a => a._id != actId);
                    activities?.push(crtActivity);
                    if(project?._milestones)
                    {
                        project._milestones._activities = activities;
                        
                        saveOnCloud(true);
                    }
                        
                }
            }
        }

        
    }

    const debugDelete = async () => {
        let tmpProject = currentProject;
        let empty: CalendarTypes = {
            _id: tmpProject?._milestones?._id,
            _activities: [],
        };
        if(tmpProject)
        {
            tmpProject._milestones = empty;
            setCurrentProject(tmpProject);
            setTasks(undefined);
            saveOnCloud();
        }
    }

    const saveOnCloud = async (refresh: boolean = true) => {
        if(currentProject)
        {
            try{

                if(refresh) setRefresh(false);
                let url = host + 'project/'+currentProject._id;
                let token = getToken();
                const response = await fetch(url, {
                    method: 'PUT',
                    headers: {
                        Authorization: 'Bearer ' + token,
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        _milestones: currentProject._milestones
                    })
                });
                const obtained = await response.json();
                if(await obtained.fail){
                    console.log(await obtained);
                }else{
                    console.log(await obtained);
                    
                    
                }
                if(refresh) setRefresh(true);
            }catch(error){
                console.log(error);
            }
        }
        
    }

    //gantt helpers
    const getStartEndDateForProject = (tasks: Task[], projectId: string) => {
        const projectTasks = tasks.filter(t => t.project === projectId);
        let start = projectTasks[0].start;
        let end = projectTasks[0].end;

        for (let i = 0; i < projectTasks.length; i++) {
            const task = projectTasks[i];
            if (start.getTime() > task.start.getTime()) {
                start = task.start;
            }
            if (end.getTime() < task.end.getTime()) {
                end = task.end;
            }
        }
        return [start, end];
    }

    function instanceOfTaskTypes(object: any): object is TaskTypes {
        return '_comment' in object;
    }
    function intanceOfActivityTypes(object: any): object is ActivityTypes {
        return '_tasks' in object;
    }

    const codeTask = (data: ActivityTypes | TaskTypes, projectId?: string) => {
        if(instanceOfTaskTypes(data))
        {
            let newTask: Task = {
                start: data._date ? new Date(data._date) : new Date(),
                end: data._endDate ? new Date(data._endDate) : new Date(),
                name: data._title,
                id: 'Task_' + projectId + "_" + data._id,
                type: "task",
                progress: parseFloat(data._percentage),
                styles: {progressColor: data._color},
                project: 'Project_' + projectId,
                dependencies: data._dependencies
            }
            return newTask;
        }else if(intanceOfActivityTypes(data)){
            let newActivity: Task = {
                start: data._initDate ? new Date(data._initDate) : new Date(),
                end: data._endDate ? new Date(data._endDate) : new Date(),
                name: data._title,
                id: 'Project_' + data._id,
                type: "project",
                progress: 0,
                hideChildren: true
            }
            return newActivity;
        }else{
            return false;
        }
    }

    const decodeTask = (data: Task) => {
        var arr: string[] = [];
        var pr: string[] = [];
        switch(data.type)
        {
            case "task":
                let updateTask: TaskTypes | undefined;
                arr = data.id.split("_");
                if(data.project)
                {
                    pr = data.project.split("_");
                    if(currentProject?._milestones)
                    {
                        if(currentProject._milestones._activities)
                        {
                            let a = currentProject._milestones._activities.find((a) => a._id == pr[pr.length - 1]);
                            if(a?._tasks)
                            {
                                updateTask = a._tasks?.find((t) => t._id == arr[arr.length - 1]);
                                if(updateTask)
                                {
                                    updateTask = {
                                        ...updateTask,
                                        _title: data.name,
                                        _endDate: data.end.toString(),
                                        _date: data.start.toString(),
                                        _percentage: data.progress.toString(),
                                        _dependencies: data.dependencies ? data.dependencies : []
                                    }
                                    return updateTask;
                                }
                            }
                        }
                    }
                }
            break;
            case "project":
                
                let updateActivity: ActivityTypes | undefined;
                arr = data.id.split("_");
                if(currentProject?._milestones)
                {
                    if(currentProject._milestones._activities)
                    {
                        updateActivity = currentProject._milestones._activities.find((a) => a._id == arr[1]);
                        if(updateActivity)
                        {
                            updateActivity = {
                                ...updateActivity,
                                _title: data.name,
                                _endDate: data.end.toString(),
                                _initDate: data.start.toString(),
                            }
                           
                            return updateActivity;
                        }
                    }
                }
            break;
        }
    }

    return {
        openDrawer,
        setOpenDrawer,
        currentScreen,
        setCurrentScreen,
        screens,
        getProjects,
        projects,
        refresh, setRefresh,
        setCurrentProject,
        currentProject,
        edit,
        setEdit,
        getConcepts,
        currentConcept,
        setCurrentConcept,
        concepts,
        setElements,
        elements,
        setCurrentElement,
        currentElement,
        providers,
        setProviders,
        currentProvider,
        setCurrentProvider,
        price,
        setPrice,
        indirectCost,
        setQuantity,
        quantity,
        saveBudget,
        resetBudget,
        history,
        name,
        setName,
        location,
        setLocation,
        type,
        setType,
        buildingType,
        setBuildingType,
        size,
        setSize,
        images,
        setImages,
        setRawLocation,
        rawLocation,
        saveNewProject,
        savePhotos,
        activityName,
        setActivityName,
        saveActivity,
        currentActivity, setCurrentActivity,
        taskName, setTaskName,
        taskDate, setTaskDate,
        taskDesc, setTaskDesc,
        saveTask,
        tasks, setTasks,
        debugDelete,
        getStartEndDateForProject,
        codeTask, decodeTask,
        intanceOfActivityTypes, instanceOfTaskTypes,
        saveOnCloud,
        currentTask, setCurrentTask,
        saveDependencie,
        currentDependencie, setCurrentDependencie,
        handleTaskChange
    }
}