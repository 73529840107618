import { alpha, Box, Divider, IconButton, InputBase, List, ListItem, ListItemButton, ListItemIcon, ListItemText, styled, Toolbar, Typography, useTheme } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import { AppBar, Drawer, DrawerHeader, Search, SearchIconWrapper, StyledInputBase } from './ArchitectsApp.components';
import { useArchitects } from './ArchitectsApp.hooks';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CssBaseline from '@mui/material/CssBaseline';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import LogoutIcon from '@mui/icons-material/Logout';
import { BudgetScreen, MainScreen, NewProjectScreen, PhotoScreen, ProjectsScreen, ScheduleScreen, SettingsScreen } from './ArchitectsApp.screens';


const ArchitectsApp = () => {
    const theme = useTheme();

    const {
        openDrawer,
        setOpenDrawer,
        currentScreen,
        setCurrentScreen,
        screens,
        setCurrentProject,
        currentProject,
        history
    } = useArchitects();

    return(
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="fixed" open={openDrawer}>
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => {setOpenDrawer(true)}}
                        sx={{
                            marginRight: 2,
                            ...(openDrawer && { display: 'none' }),
                          }}
                    >
                    <MenuIcon />
                    </IconButton>
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
                        >
                        Broissin
                    </Typography>
                    <Search>
                        <SearchIconWrapper>
                            <SearchIcon />
                        </SearchIconWrapper>
                        <StyledInputBase
                            placeholder="Search…"
                            inputProps={{ 'aria-label': 'search' }}
                        />
                    </Search>
                </Toolbar>
            </AppBar>
            <Drawer variant="permanent" open={openDrawer}>
                <DrawerHeader>
                    <IconButton onClick={() => {setOpenDrawer(false)}}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <List>
                {screens.map((text, index) => (
                    <ListItem key={text} disablePadding sx={{ display: 'block' }}>
                        <ListItemButton
                            sx={{
                            minHeight: 48,
                            justifyContent: openDrawer ? 'initial' : 'center',
                            px: 2.5,
                            }}
                            onClick={
                                () => {
                                    setCurrentScreen(index);
                                    setCurrentProject(undefined);
                                }
                            }
                        >
                            <ListItemIcon
                            sx={{
                                minWidth: 0,
                                mr: openDrawer ? 3 : 'auto',
                                justifyContent: 'center',
                            }}
                            >
                            {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                            </ListItemIcon>
                            <ListItemText primary={text} sx={{ opacity: openDrawer ? 1 : 0 }} />
                        </ListItemButton>
                    </ListItem>
                ))}
                <ListItem key="Logout" disablePadding sx={{ display: 'block' }}>
                        <ListItemButton
                            sx={{
                            minHeight: 48,
                            justifyContent: openDrawer ? 'initial' : 'center',
                            px: 2.5,
                            }}
                            onClick={
                                () => {
                                    // setCurrentScreen(index);
                                    localStorage.clear();
                                    history.push("/");
                                }
                            }
                        >
                            <ListItemIcon
                            sx={{
                                minWidth: 0,
                                mr: openDrawer ? 3 : 'auto',
                                justifyContent: 'center',
                            }}
                            >
                            {/* {index % 2 === 0 ? <InboxIcon /> : <MailIcon />} */}
                            <LogoutIcon />
                            </ListItemIcon>
                            <ListItemText primary="Cerrar sesión" sx={{ opacity: openDrawer ? 1 : 0 }} />
                        </ListItemButton>
                    </ListItem>
                </List>
                
            </Drawer>
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <DrawerHeader/>
                {
                    currentScreen == 0 ? <MainScreen/> : 
                    currentScreen == 1 ? <ProjectsScreen 
                                            onProjectChange={(value) => setCurrentProject(value)} 
                                            onScreenChange={(value) => setCurrentScreen(value)}
                                            project={currentProject}
                                        /> :
                    currentScreen == 2 ? <SettingsScreen /> :
                    currentScreen == 4 ? <BudgetScreen 
                                            project={currentProject}
                                            onScreenChange={(value) => setCurrentScreen(value)}
                                        /> :
                    currentScreen == 5 ? <NewProjectScreen
                                            onScreenChange={(value) => setCurrentScreen(value)}
                                        /> :
                    currentScreen == 6 ? <PhotoScreen
                                            project={currentProject}
                                            onScreenChange={(value) => setCurrentScreen(value)}
                                        /> :
                    currentScreen == 7 ? <ScheduleScreen
                                            project={currentProject}
                                            setProject={(value) => setCurrentProject(value)}
                                            onScreenChange={(value) => setCurrentScreen(value)}
                                        /> :
                    null
                }
            </Box>
        </Box>
    )
}

export default ArchitectsApp;