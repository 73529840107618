import React, { useEffect } from "react";
import { Button, Checkbox, Input, Modal} from 'rsuite';
import { useLogin } from "./IndexScreen.hooks";
import styles from './IndexScreen.module.sass';
import globalStyles from '../../global/globalStyles.module.sass';
import broissinLogo from '../../assets/img/BroissinSLogo.svg';
import { useHistory } from "react-router-dom";
import { useValidation } from "../../components/validation";
import TextField from '@mui/material/TextField';
import { host } from "../../env";

const IndexScreen = () => {
    const history = useHistory();

    const verifyLocalStorage = async() => {
        if (localStorage.getItem('access_token')) {
            
            const response = await fetch(host+'login',{
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('access_token')
                }
            });
            const obtained = await response.json();
            if(obtained.user._rol=='studio-director'){
                history.push("/app"); 
            }
            if(obtained.user._rol=='administrator'){
                history.push("/admin"); 
            }
        }
    }

    useEffect(()=>{
        console.log("verificaré");
        verifyLocalStorage();
    }, []);

    const { 
        openModal, 
        isOpenModal, 
        isRegisterActive,
        closeRegister,
        openRegister,
        closeModal, 
        setUsername, 
        setPassword, 
        login,
        register,
        setName,
        name,
        setLastName,
        lastName,
        setLastName2,
        setPhone,
        phone,
        setPassword2,
        password,
        error,
        setError,
        setEmail
    } = useLogin();

    const {
        ValidateEmail,
        ValidatePhone
    } = useValidation();

    //Logic animation
    const hide = globalStyles.hide + " " + styles.hide;
    const show = globalStyles.show + " " + styles.show;
    const showLogin = (isRegisterActive ? hide : show);
    const showRegister = (isRegisterActive ? show : hide);

    return(
        <div className={styles.container }>
            <div className={styles.leftWrapper }>
                <div className={styles.wrapper + " " + showLogin }>
                    
                    <h1>¡Hola!</h1>
                    <p>Inicia sesión con tu cuenta</p>
                    <br/>
                    <div className={styles.inputContainer }>
                        <TextField label="Username" fullWidth placeholder="Username" margin="normal" onChange={
                            (event: any) =>{
                                setUsername(event.target.value);
                            }
                        } />
                        <br/>
                        <TextField label="Password" fullWidth placeholder="Password" margin="normal" type="password" onChange={
                            (event: any) =>{
                                setPassword(event.target.value);
                            }
                         } />
                        <div className={styles.rememberContainer}>
                            <Checkbox>Recordarme</Checkbox>
                            <a >Olvidé mi contraseña</a>
                        </div>
                        
                        <br />
                        <br />
                        <div className={globalStyles.ButtonWrapper}>
                            <Button 
                            
                            onClick={() => {
                                login();
                            }} className={globalStyles.Button}>
                                Entra
                            </Button>
                        </div>        
                        <br />
                        <br />
                        <div className={styles.registerContainer}>
                            <p>¿No tienes una cuenta? </p>
                            <a onClick={() => {openRegister();}}>¡Crea una!</a>    
                        </div>                
                    </div>
                </div>
                <div className={styles.registerWrapper + " " + showRegister}>
                    <h1>¡Bienvenido a bordo!</h1>
                    <p>Crea una nueva cuenta</p>
                    <br/>
                    <div className={styles.inputContainer }>
                        <TextField 
                            label="Nombre" 
                            margin="dense" 
                            fullWidth 
                            placeholder="Nombre" 
                            onChange={
                            (event: any) => {
                                setName(event.target.value);
                            }
                        } />
                        <br/>
                        <TextField label="Apellido Paterno" margin="dense" fullWidth placeholder="Apellido Paterno" onChange={
                            (event: any) => {
                                setLastName(event.target.value);
                            }
                        } />
                        <br/>
                        <TextField label="Apellido Materno" margin="dense" fullWidth placeholder="Apellido Materno" onChange={
                            (event: any) => {
                                setLastName2(event.target.value);
                            }
                        } />
                        <br/>
                        <TextField 
                            helperText={error.phone.isError ? error.phone.message : ""} 
                            error={error.phone.isError} 
                            margin="dense" 
                            fullWidth 
                            label="Teléfono" 
                            placeholder="Teléfono" 
                            onChange={
                            (event: any) => {
                                if(ValidatePhone(event.target.value))
                                {
                                    setPhone(event.target.value);
                                    setError({
                                        ...error,
                                        phone: {
                                            isError: false,
                                            message: error.phone.message
                                        }
                                    })
                                }else{
                                    setError({
                                        ...error,
                                        phone: {
                                            isError: true,
                                            message: error.phone.message
                                        }
                                    })
                                }
                                
                            }
                        } />
                        <br/>
                        <TextField 
                            margin="dense"
                            fullWidth
                            placeholder="Email" 
                            helperText={error.email.isError ? error.email.message : ""}
                            error={error.email.isError}
                            onChange={
                                (event: any) => {
                                    if(ValidateEmail(event.target.value)) {
                                        setEmail(event.target.value);
                                        setError({
                                            ...error,
                                            email: {
                                                isError: false,
                                                message: error.email.message
                                            }
                                        })
                                    }else{
                                        setError({
                                            ...error,
                                            email: {
                                                isError: true,
                                                message: error.email.message
                                            }
                                        })
                                    }
                                }
                            } 
                        />
                        <br />
                        <TextField error={error.password.isError} fullWidth placeholder="Password" type="password" onChange={
                            (event: any) => {
                                setPassword(event.target.value);
                            }
                         } />
                        <br/>
                        <TextField 
                            margin="dense" 
                            fullWidth 
                            placeholder="Repeat Password" 
                            type="password" 
                            helperText={error.password.isError ? error.password.message : ""}
                            error={error.password.isError}
                            onChange={
                            (event: any) => {
                                if(event.target.value == password)
                                {
                                    setPassword2(event.target.value)
                                    setError({
                                        ...error,
                                        password: {
                                            isError: false,
                                            message: error.password.message
                                        }
                                    })
                                }else{
                                    setError({
                                        ...error,
                                        password: {
                                            isError: true,
                                            message: error.password.message
                                        }
                                    })
                                }
                            }
                         } />
                        <div><Checkbox></Checkbox>He leído y acepto los <a>términos y condiciones</a></div>
                        
                        <br />
                        <br />
                        <div className={globalStyles.ButtonWrapper}>
                            <Button 
                            
                            onClick={() => {
                                register();
                            }} className={globalStyles.Button}>
                                Registrate
                            </Button>
                        </div>        
                        <br />
                        <br />
                        <div className={styles.registerContainer}>
                            <p>¿Ya eres miembro? </p>
                            <a onClick={() => {closeRegister();}}>¡Accede!</a>    
                        </div>                
                    </div>
                </div>
                
            </div>
            <div className={styles.rightWrapper}>
                
                <div className={styles.wrapper}>
                    <img src={broissinLogo} />
                    <h1>
                        {isRegisterActive ? "¿Estás listo para cambiar?" : "¡Bienvenido de nuevo!"}
                    </h1>
                    {
                        isRegisterActive ? 
                        (<p>Tendras más tiempo para lo que te gusta<span>😍</span></p>) : 
                        (<p>Ocupate en diseñar, no en administrar <span>😉📐</span></p>)
                    }
                    
                </div>
            </div>
            
        </div>
    );
}

export default IndexScreen;