import React, { Dispatch, SetStateAction, useState } from "react";
import { Button, Input } from "rsuite";
import UploaderBroissin from "../UploaderBroissin";
import useFollowingContactsForm from "./FollowingContactsForm.hooks";
import { FollowingContactsProps } from "./FollowingContactsForm.types";



const FollowingContactsForm = (props: FollowingContactsProps) => {

    const{
        setName,
        setPhoneField,
        setCellPhone,
        setEmail,
        setArea,
        setPosition,
        addFollowingContact,
        setIneImage
    } = useFollowingContactsForm(props);

    return (
        <div>
            <Input placeholder="Personal designado para trámites ante la Institución" onChange={setName} />
            <UploaderBroissin setValue={setIneImage} text="Copia de INE" />
            <div style={{ display: "flex" }}>
                <Input placeholder="Teléfono Oficina y Extensión" onChange={setPhoneField} />
                <Input placeholder="Celular" onChange={setCellPhone} />
                <Input placeholder="Correo electrónico" onChange={setEmail} />
                
            </div>
            <div style={{ display: "flex" }}>
                <Input placeholder="Area de Adscripción" onChange={setArea} />
                <Input placeholder="Cargo que desempeña" onChange={setPosition} />
                <Button
                    onClick={addFollowingContact}
                >Agregar</Button>
            </div>
        </div>
    )
}

export default FollowingContactsForm;