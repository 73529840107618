import React, { useEffect, useState } from "react";
import { Button, Input, InputPicker, Table, InputGroup } from 'rsuite';
import styles from './ProfileView.module.sass';

const exampleData = [
    {
        "id": "1",
        "element": "Plástico de protección",
        "unit": "Rollo",
        "unitprice": "$1.764,71",
        "cost": "$1.500,00",
    },
    {
        "id": "2",
        "element": "Plástico de protección",
        "unit": "Rollo",
        "unitprice": "$1.764,71",
        "cost": "$1.500,00",
    },
    {
        "id": "3",
        "element": "Plástico de protección",
        "unit": "Rollo",
        "unitprice": "$1.764,71",
        "cost": "$1.500,00",
    },
  ];

const ProfileView = () => {

    //Control
    const [readOnly, setReadOnly] = useState(true);


    //Test data
    const [data, setData] = useState({
        name: "Alexander Ceron",
        type: "Contratista",
        initdate: "01/01/2020",
        tel: "555-555-5555",
        mail: "email@email.com",
        cal: "10",
        cost: "5",
    });

    return(
        <div className={styles.mainView}>
            {
                readOnly && (
                    <div className={styles.infoView}>
                        <h1>{data.name}</h1>
                        <p>{data.type}</p>
                        <p>{data.initdate}</p>
                    </div>
                )
            }
            {
                !readOnly && (
                    <div className={styles.infoView}>
                        <Input disabled={readOnly} placeholder={data.name} />
                        <Input disabled={readOnly} placeholder={data.type} />
                        <Input disabled={readOnly} placeholder={data.initdate} />
                    </div>
                )
            }
            
            <div className={styles.dataView}>
                <div className={styles.dataWrapper}>
                    <div>
                        <h4>Teléfono</h4>
                        <Input disabled={readOnly} placeholder={data.tel} />
                    </div>
                    <div>
                        <h4>Correo</h4>
                        <Input disabled={readOnly} placeholder={data.mail} />
                    </div>
                    <div>
                        <h4>Calificación</h4>
                        <Input disabled={readOnly} placeholder={data.cal} />
                    </div>
                    <div>
                        <h4>Costos</h4>
                        <Input disabled={readOnly} placeholder={data.cost} />
                    </div>
                    <div>
                        {
                            readOnly && (<Button onClick={() => setReadOnly(false)} appearance="primary">Editar</Button>)
                        }
                        {
                            !readOnly && (<Button onClick={() => setReadOnly(true)} appearance="primary">Guardar</Button>)
                        }
                    </div>
                </div>
                <div className={styles.listWrapper}>
                    <Table
                        data={exampleData}
                    >
                        <Table.Column width={400} align="center" fixed sortable>
                        <Table.HeaderCell>Elemento</Table.HeaderCell>
                        <Table.Cell dataKey="element" />
                        </Table.Column>
                        <Table.Column width={200} align="center" fixed resizable sortable>
                        <Table.HeaderCell>Unidad</Table.HeaderCell>
                        <Table.Cell dataKey="unit" />
                        </Table.Column>
                        <Table.Column width={200} align="center" fixed sortable>
                        <Table.HeaderCell>Precio Unitario</Table.HeaderCell>
                        <Table.Cell dataKey="unitprice" />
                        </Table.Column>
                        <Table.Column width={200} align="center" fixed sortable>
                        <Table.HeaderCell>Costo Directo</Table.HeaderCell>
                        <Table.Cell dataKey="cost" />
                        </Table.Column>
                        <Table.Column width={300} align="center" fixed>
                        <Table.HeaderCell>Action</Table.HeaderCell>

                        <Table.Cell>
                            <span>
                            <a onClick={()=>{}}> Editar </a> | <a onClick={()=>{}}> Deshabilitar </a>
                            </span>
                        </Table.Cell>
                        </Table.Column>
                    </Table>
                </div>
            </div>
        </div>
    )
}

export default ProfileView;