import React, { useState } from 'react';
import {
    Button,
    Input,
    InputPicker
} from 'rsuite';
import globalStyles from '../../global/globalStyles.module.sass';
import { host } from '../../env';
const NewStudioForm = () =>{


    const [name, setName] = useState('');
    const [specialty, setSpecialty] = useState('');
    const [status, setStatus] = useState('0');

    

    const addStudio = async()=>{
        const url = host+'studio';
        const token = await localStorage.getItem('access_token');
        const response = await fetch(url,{
            method: 'POST',
            headers:{
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+token
            },
            body: JSON.stringify({
                _name: name,
                _specialty: specialty,
                _status: status
            })
        })

        const obtained = await response.json();
        if(!obtained.fail){
            alert('Estudio agregado')
        }else{
            alert('Ha ocurrido un error')
        }
    }

    return(
        <div className={globalStyles.FormWrapper}>
            <div  >
                <div>Nombre: </div>
                <Input onChange={(response)=>setName(response.toString())} />
                <div>Especialidad: </div>
                <Input onChange={(response)=>setSpecialty(response.toString())} />
                <div>Status: </div>
                <InputPicker 
                    data={[{label: 'Inactivo', value: '0', rol: '0'}, {label: 'Activo', value: '1', rol: '1'}]}
                    onChange={(response)=>{
                        setStatus(response);
                    }}
                />
                <Button
                    onClick={()=>{
                        addStudio();
                    }}
                >Agregar</Button>
            </div>
        </div>
    )
}

export default NewStudioForm;