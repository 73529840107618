import React,{useState, useEffect} from "react";
import { host } from "../../env";
import { ConceptViewProps } from "./ConceptView.types";

const useConceptView = ({data, functionAtTheEnd}: ConceptViewProps) => {
    const [name, setName] = useState<string | number | readonly string[]>((data!=null) ? data._name : "");
    const [description, setDescription] = useState<string | number | readonly string[]>((data != null) ? data._description : "");
    const [unit, setUnit] = useState<string | number | readonly string[]>((data != null) ? data._unit : "");
    const [unit_price, setUnit_price] = useState<string | number | readonly string[]>((data != null) ? data._price : "");
    //const [direct_cost, setDirect_cost] = useState<string | number | readonly string[]>((data != null) ? data.direct_cost : "");
    const addOrChangeConcept = async () => {
        console.log('tengo: ', data);
        let method = 'POST';
        let url = 'concepts';
        if (data) {
            method = 'PUT';
            url = `concept/${data._id}`;
        }
        let token = await localStorage.getItem('access_token');
        const response = await fetch(host + url, {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                _name: name,
                // direct_cost: direct_cost,
                _status: data ? data._status : 'active'
            })
        })
        const obtained = await response.json();
        alert(obtained.message);
        functionAtTheEnd();
    }

    return{
        addOrChangeConcept,
        name,
        setName,
        description,
        setDescription,
        unit,
        setUnit,
        unit_price,
        setUnit_price,
        //direct_cost,
        //setDirect_cost,
        data
    }
}

export default useConceptView;