import React, { Dispatch, SetStateAction, useState } from "react";
import { Button, Input } from "rsuite";
import UploaderBroissin from "../UploaderBroissin";
import useShareholdersDataForm from "./ShareholdersDataForm.hooks";
import { ShareholdersDataFormProps } from "./ShareholdersDataForm.types";



const ShareholdersDataForm = (props: ShareholdersDataFormProps) => {

    const {
        setName,
        setRfc,
        setFolio,
        addShareHolder,
        setIneCopy,
        setRfcCopy
    } = useShareholdersDataForm(props);

    return (
        <div>
            <Input placeholder="Nombre completo" onChange={setName} />
            <UploaderBroissin disableAfterOneUse setValue={setIneCopy} text="Copia fotostática del INE" />
            <div style={{ display: "flex" }}>
                <Input placeholder="R.F.C." onChange={setRfc} />
                <UploaderBroissin disableAfterOneUse setValue={setRfcCopy} text="Copia fotostática del RFC" />
                <Input placeholder="Folio de la identificación oficial" onChange={setFolio} />
                <Button onClick={addShareHolder} >Agregar</Button>
            </div>

        </div>
    );
}

export default ShareholdersDataForm;