import { useEffect, useState } from "react";
import { host } from "../../env";

export const useNewElementForm = () => {
    // const host = 'http://localhost:4000/';
    // const host = '/';
    const [providers, setProviders] = <any[any]>useState([]);
    const [concepts, setConcepts] = <any[any]>useState([]);
    const getProviders = async () => {
        const response = await fetch(host + 'providers');
        const data = await response.json();

        let temporalProviders: {}[] = [];
        await data.message.map((provider: any) => {
            temporalProviders.push({
                label: provider._socialReason,
                value: provider._id,
                rol: provider._id
            });
        })

        setProviders(await temporalProviders);
    };

    const getConcepts = async() =>{
        const response = await fetch(host + 'concepts');
        const data = await response.json();
        let temporalConcepts : {}[] = []
        await data.concepts.map((concept: any)=>{
            temporalConcepts.push({
                label: concept._name,
                value: concept._id,
                rol: concept._id
            })
        });
        setConcepts(temporalConcepts);
    }

    const [name, setName] = useState<string | number | readonly string[]>('');
    const [description, setDescription] = useState<string | number | readonly string[]>('');
    const [provider, setProvider] = useState('');
    const [concept, setConcept] = useState('');
    const [unit, setUnit] = useState<string | number | readonly string[]>('');
    const [unit_price, setUnitPrice] = useState<string | number | readonly string[]>('');
    const [direct_cost, setDirectCost] = useState<string | number | readonly string[]>('');
    const [status, setStatus] = useState('2');

    useEffect(()=>{
        getProviders();
        getConcepts();
    },[]);

    const addElement = async () => {
        const response = await fetch(host + 'elements', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('access_token')
            },
            body: JSON.stringify({
                _name: name,
                _description: description,
                _provider: provider,
                _concept: concept,
                _unit: unit,
                _price: unit_price,
                _directCost: direct_cost,
                _status: status,
            })
        });
        const data = await response.json();
        console.log(data);
    };

    return {
        providers,
        setName,
        setDescription,
        setProvider,
        setUnit,
        setUnitPrice,
        setDirectCost,
        setStatus,
        addElement,
        status,
        concepts,
        setConcept
    }
};