import React, { useEffect, useState } from "react";
import { Button, Input, InputPicker, Table, InputGroup } from 'rsuite';
import globalStyles from '../../global/globalStyles.module.sass';
import styles from './ProviderCatalogue.module.sass';
import SearchIcon from '@rsuite/icons/Search';
import ProfileView from '../ProfileView';
import ArowBackIcon from '@rsuite/icons/ArowBack';
const exampleData = [
    {
        "id": "1",
        "name": "Alexander Ceron",
        "type": "Contratista",
        "initdate": "01/01/2020",
        "tel": "555-555-5555",
        "mail": "email@email.com",
        "cal": "10",
        "cost": "5",
    },
    {
        "id": "2",
        "name": "Abudio Velazquez",
        "type": "Carpintero",
        "initdate": "01/01/2020",
        "tel": "555-555-5555",
        "mail": "email@email.com",
        "cal": "10",
        "cost": "5",
    },
    // {
    //     "id": "1",
    //     "name": "Alejandra Diep",
    //     "type": "Iluminación",
    //     "initdate": "01/01/2020",
    //     "tel": "555-555-5555",
    //     "mail": "email@email.com",
    //     "cal": "10",
    //     "cost": "5",
    // },
  ];



const ProviderCatalogue = () => {
    const [providerView, setProvidersView] = useState(false);

    return (
        <div >
            
            {
                !providerView && (
                    <div>
                        <div className={styles.TopOptions}>
                        <div className={styles.SearchBarWrapper}>
                            <InputGroup >
                            <Input placeholder="Busqueda" />
                            <InputGroup.Addon>
                                <SearchIcon />
                            </InputGroup.Addon>
                            </InputGroup>
                        </div>
                        <div className={styles.ButtonWrapper}>
                            <Button appearance="primary">Agregar Proveedor</Button>
                        </div>
                        </div>
                        

                        <Table
                            data={exampleData}
                        >
                            <Table.Column width={70} align="center" fixed sortable>
                            <Table.HeaderCell>Id</Table.HeaderCell>
                            <Table.Cell dataKey="id" />
                            </Table.Column>
                            <Table.Column width={200} align="center" fixed resizable sortable>
                            <Table.HeaderCell>Nombre</Table.HeaderCell>
                            <Table.Cell dataKey="name" />
                            </Table.Column>
                            <Table.Column width={200} align="center" fixed sortable>
                            <Table.HeaderCell>Tipo</Table.HeaderCell>
                            <Table.Cell dataKey="type" />
                            </Table.Column>
                            <Table.Column width={100} align="center" fixed resizable>
                            <Table.HeaderCell>Fecha de Inicio</Table.HeaderCell>
                            <Table.Cell dataKey="initdate" />
                            </Table.Column>
                            <Table.Column width={200} align="center" fixed sortable>
                            <Table.HeaderCell>Teléfono</Table.HeaderCell>
                            <Table.Cell dataKey="tel" />
                            </Table.Column>
                            <Table.Column width={150} align="center" fixed sortable>
                            <Table.HeaderCell>Correo</Table.HeaderCell>
                            <Table.Cell dataKey="mail" />
                            </Table.Column>
                            <Table.Column width={120} align="center" fixed sortable>
                            <Table.HeaderCell>Calificación</Table.HeaderCell>
                            <Table.Cell dataKey="cal" />
                            </Table.Column>
                            <Table.Column width={100} align="center" fixed sortable>
                            <Table.HeaderCell>Costos</Table.HeaderCell>
                            <Table.Cell dataKey="cost" />
                            </Table.Column>
                            <Table.Column width={300} align="center" fixed>
                            <Table.HeaderCell>Action</Table.HeaderCell>

                            <Table.Cell>
                                <span>
                                <a onClick={()=>{setProvidersView(true)}}> Editar </a> | <a onClick={()=>{}}> Deshabilitar </a>
                                </span>
                            </Table.Cell>
                            </Table.Column>
                        </Table>
                    </div>
                )
            }
            {
                providerView && (
                    <div>
                        <a onClick={()=>{setProvidersView(false)}} className={styles.TopIcon}>
                            <ArowBackIcon/>
                        </a>
                        <ProfileView/>
                    </div>
                    
                )
            }
        </div>
    );
};

export default ProviderCatalogue;